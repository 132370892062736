import React, { Component } from 'react';
import Slider from "react-slick";
import { Fade } from "react-awesome-reveal";

import img from '../../../assets/images/facility/mb-1.jpg';

import img1 from '../../../assets/images/facility/b1.jpg';
import img2 from '../../../assets/images/facility/f16.jpg';
import img8 from '../../../assets/images/facility/b6.jpg';
import img3 from '../../../assets/images/facility/b7.jpg';
import img4 from '../../../assets/images/facility/b5.jpg';
import img5 from '../../../assets/images/facility/b3.jpg';
import img6 from '../../../assets/images/facility/b2.jpg';
import img7 from '../../../assets/images/facility/b8.jpg';

import { bounce } from 'react-animations';

const bannerDetail = [
    { img: img, icon:'', direction1:"down", delay1:0, direction:"left", delay:800, title: 'Mini Ooty at Erode', left: "The Heaven's Gate", right: 'Admin', btn_link: '' },
    { img: img1, icon:'', direction1:"down", delay1:0, direction:"left", delay:800,  title: 'Mini Ooty at Erode', left: "The Heaven's Gate", right: 'Admin', btn_link: '' },
    { img: img2, icon:'', direction1:"down", delay1:0, direction:"left", delay:800,  title: 'Mini Ooty at Erode', left: "The Heaven's Gate", right: 'Admin', btn_link: '' },
    { img: img3, icon:'', direction1:"down", delay1:0, direction:"left", delay:800, title: 'Mini Ooty at Erode', left: "The Heaven's Gate", right: 'Admin', btn_link: '' },
    { img: img4, icon:'', direction1:"down", delay1:0, direction:"left", delay:800,  title: 'Mini Ooty at Erode', left: "The Heaven's Gate", right: 'Admin', btn_link: '' },
    { img: img5, icon:'', direction1:"down", delay1:0, direction:"left", delay:800,  title: 'Mini Ooty at Erode', left: "The Heaven's Gate", right: 'Admin', btn_link: '' },
    { img: img6, icon:'', direction1:"down", delay1:0, direction:"left", delay:800,  title: 'Mini Ooty at Erode', left: "The Heaven's Gate", right: 'Admin', btn_link: '' },
    { img: img7, icon:'', direction1:"down", delay1:0, direction:"left", delay:800,  title: 'Mini Ooty at Erode', left: "The Heaven's Gate", right: 'Admin', btn_link: '' },
    { img: img8, icon:'', direction1:"down", delay1:0, direction:"left", delay:800,  title: 'Mini Ooty at Erode', left: "The Heaven's Gate", right: 'Admin', btn_link: '' },
    // { img: img7, title: 'New Retro Collection.', postdate: '28th Aug 2020', authorname: 'Admin', text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit' },
    // { img: img8, title: 'Special Wedding Rings.', postdate: '28th Aug 2020', authorname: 'Admin', text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit' },
];
export default class  Banner extends Component {
    constructor(props) {
        super(props);
    }
    
    render() {
        const settings = {
            slidesToShow: 1,
            slidesToScroll: 1,
            fade: true,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            arrows: false,
            dots: false,
            responsive: [{
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                },
            },
            ],
        }
        return (
            <section className="latest-news pt-5 pb-1">
                <div className="">
               
                    <Slider className="row latest-post-slider mt-3" ref={c => (this.slider = c)} {...settings}>
                        {bannerDetail.map((item, i) => (
                            <div key={i} className="col-lg-12">
                                <div className="latest-post-box w-100">
                                    <div className="post-img" style={{ backgroundImage: "url(" + item.img + ")" }} />
                                    <div className="silder-content d-flex justify-content-start w-100"><div><div className="silde-card-banner">
                                    <Fade delay={item.delay1} direction={item.direction1}><div className="silder-title">
                                        "{item.title}"
                                        </div></Fade>
                                        <Fade delay={item.delay} direction={item.direction}>
                                        <div className="silder-bottom">
                                        "{item.left}"
                                        </div>
                                        </Fade></div></div>
                                    </div>
                                    
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </section>

        );
    }
}

