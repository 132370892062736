import React from "react";
import Slider from "react-slick";
import border from '../../../assets/images/d-border.png';
import Reveal, { Fade } from "react-awesome-reveal";
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';


import img7 from '../../../assets/images/original/about/1.jpg';
import img1 from '../../../assets/images/original/about/2.jpg';
import img2 from '../../../assets/images/original/about/3.jpg';
import img3 from '../../../assets/images/original/about/4.jpg';
import img4 from '../../../assets/images/original/about/5.jpg';
import img5 from '../../../assets/images/original/about/6.jpg';
import img6 from '../../../assets/images/original/about/7.jpg';

const aboutDetail = [
  { img: img7, icon: '', title: 'Lorem ipsum dolor sit amet', },
  { img: img1, icon: '', title: 'Lorem ipsum dolor sit amet' },
  { img: img2, icon: '', title: 'Lorem ipsum dolor sit amet', },
  { img: img3, icon: '', title: 'Lorem ipsum dolor sit amet', },
  { img: img4, icon: '', title: 'Lorem ipsum dolor sit amet', },
  { img: img5, icon: '', title: 'Lorem ipsum dolor sit amet', },
  { img: img6, icon: '', title: 'Lorem ipsum dolor sit amet', },

];

export default function AboutUs() {
  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    dots: false,
    responsive: [{
      breakpoint: 992,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
      },
    },
    ],
  }
  return (
    <section className="p-4 mt-0">
      <div className="bg-color-header">
      <div className="justify-content-start about-title">
        <div>About Our Heaven's Gate</div>  <div className="bottom-border"></div>
      </div>
   
      </div>
      <div className="row  mb-5">

        <div className="col-12 col-sm-7 col-md-12 col-lg-7"><Fade direction={"right"} delay={900}>
        
          <div className="pt-0 about-sub-text">
          <div className="justify-content-center d-flex welcome-text">"A mini Ooty at Erode District"</div>
            <p className="sub-text">
              <ArrowRightAltIcon className="a-icons"/>Moonwalk Resorts is a very serene and peaceful environment inside a dense forest. 
                It is the perfect escape for hill and nature lovers. It is situated at 1160 msl and it is the highest place at Thamaraikarai hillstation.
            </p>
            <p className="sub-text">
              <ArrowRightAltIcon className="a-icons"/>The Wildlife Safari is a must experience here at mid night. A nature walk at early morning is a must do thing to experience the dawn beautifully at Moonwalk Resorts. </p>
            <p className="sub-text">
              <ArrowRightAltIcon className="a-icons"/>A magical destination for a holiday trip and weekend funs in our mighty hill land. </p>

                <div className="justify-content-center d-flex welcome-text">Welcome to the Moonwalk Resorts </div>
           
          </div>
          </Fade>
        </div>
        <div className="col-12 col-sm-5 col-md-12 col-lg-5">
        <Fade direction={"left"} delay={800}>
          <div className="about-image-card">
            <Slider className="row latest-post-slider" {...settings}>
              {aboutDetail.map((item, i) => (
                <img src={item.img} alt="Image not found" />
              ))}
            </Slider>
          </div>
          </Fade>
        </div>

        <div className="col-12 pt-3">
          <div className="join-membership about-sub-text">
           <div className="">The climate will never vary in our heavenly land, it will always be a soothing stay at Moonwalk Resorts and no matter
           wherever you go and whatever you feel. The presence of nature will always bring a sunshine in your life
            and peace of mind is a rare gift, peaceful sleeps and peaceful spirits are all our luxuries that few 
            can never afford but that you can feel in our heavenly land.</div>
            <div className="pt-2"> Come join with us and make your stay a remarkable and adventurous one and take back unforgettable travel experiences...</div>
          </div>
        </div>
      </div>
    </section>
  );
}
