import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import img from '../../assets/images/social-media/fb.png';
import img1 from '../../assets/images/social-media/map.png';
import img2 from '../../assets/images/social-media/wa.png';
import img3 from '../../assets/images/social-media/insta.png';
import img4 from '../../assets/images/social-media/youtube.png';

class SocialMediaIcon extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    // Back to top
    componentDidMount() {
        window.addEventListener('scroll', () => {
            this.setState({
                isTop: window.scrollY > 300
            });
        }, false);
    }
    scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
    render() {
        const backtotop = this.state.isTop ? 'active' : '';
        return (
            <div to="#" className={`bottom-social-media ${backtotop}`} onClick={() => this.scrollToTop()}>
                <div>
                <div className="social-links1">
                                            <a href="https://www.facebook.com/MoonwalkResorts/" target="_blank"><img src={img}/></a>
                                            {/* <Link to=""><img src={img1}/></Link> */}
                                            <a  href="https://api.whatsapp.com/send?phone=+919445526699" target="_blank"><img src={img2}/></a>
                                            <a href="https://www.instagram.com/moonwalkresorts/?utm_medium=copy_link" target="_blank"><img src={img3}/></a>
                                            {/* <Link to="#"><img src={img4}/></Link> */}
                                        </div>
                </div>
            </div>
        );
    }
}

export default SocialMediaIcon;