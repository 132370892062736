import React, { useEffect } from "react";

import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import StarRateIcon from '@mui/icons-material/StarRate';
import border from '../../../assets/images/under/i3.png';
import "./style.css"

export default function Reservation() {
    useEffect(() => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }, [])

    return (
        <div>
            <Header />
            <section className="mt-5">
                <div className="container">
                    <div className="d-flex justify-content-center about-title terms-title mt-5">

                        <h2 className="mt-5 pt-3">RESERVATION</h2>
                    </div>

                    <div className="d-flex justify-content-center header-img"><img src={border} /></div>
                    <div className="contact-card">
                        <div className="row mt-3 mb-5">

                            <div className="col-12">
                                <div className="p-content">
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>Please make advance reservation via telephone or book online to ensure room availability.
                                    </div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>Advance in Cash or deposit /online Payment is mandatory to obtain the confirmed reservation. Please read the cancellation policy before obtaining the confirmed reservation.
                                    </div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>Rates displayed online are not guaranteed until the confirmation voucher is generated. The rates are generally dynamic in nature. Once a rate is selected and transaction is executed by you, no other rate is applicable either higher or lower and enforce no refund is admissible
                                    </div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>The estimated cost for stay includes the confirmed room rate, the estimated taxes, and estimated fees.
                                    </div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>The actual taxes, fees and cess prevalent at the time of stay shall be applicable, which may vary from the estimates and you are liable to pay any extra cost on account of such amendments in taxes, fees, cess etc.
                                    </div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/> We reserve the right to modify or cancel the reservation if it appears, at sole discretion of the resort, that a guest is engaged in fraudulent, illegal or other inappropriate activity or the reservation is obtained fraudulently or with malafide intention or mistake or error.
                                    </div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>On check in to the resort, please present the resort reservation confirmation voucher along with valid photo ID or Passport, Photo PAN Card, Election Identity Card, Photo driving license, or any photo ID card issued by Central/ State government and a valid credit card for any incidental and/ or extra charges.
                                    </div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>All promotion/sale rates are restrictive fares and are non-refundable. Certain promotion/sale rates do not permit any changes in the resort booking. Please check restrictions on the rate while booking. Under all promotion(s)/sale rates, limited inventory is available on select Room type(s) only on first come first served basis. Moonwalk Resorts reserves the right to withdraw and/or amend the promotion/sale without any prior notice.
                                    </div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>All extra services & amenities not part of this offer will be available at an on additional charge only.
                                    </div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>This offer cannot be combined with any other offer/promotion or benefit(s) available through any loyalty program run by Moonwalk Resorts or any third party. In case of non-availability of pre-booked room at the time of arrival, the resort will offer an alternate similar standard resort in a similar room type, at its discretion and without any further liability.
                                    </div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>Rates may change without notice and may vary for special events except for confirmed reservation against the advance payment. All rates are subject to prevailing local taxes as applicable on room tariff & services opted.
                                    </div>
                                    <div>
                                    </div>
                                </div>

                                <div className="p-content">
                                    <div class="justify-content-start room-title">
                                        <div>OCCUPANCY</div>
                                        <div class="bottom-border"></div></div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>Normal occupancy permits maximum two people per room. Additional person (if room size permits) is charge extra as per prevalent rate of the resort.
                                    </div>
                                    <div>
                                    </div>
                                </div>

                                <div className="p-content">
                                    <div class="justify-content-start room-title">
                                        <div>DEPOSIT/ ONLINE PAYMENT</div>
                                        <div class="bottom-border"></div></div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>The deposit / online payment to be paid in advance equivalent to total room nights booked for the entire stay to get the confirmed reservation.
                                    </div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>We accept deposit only against the Maestro, Visa and MasterCard. For any alternate mode of payment, please contact the resort’s reservations.
                                    </div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>Corporate reservations need to be secured by a company credit card. For any alternate mode of payment, please contact Resort’s Reservation.
                                    </div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>Group bookings of four or more rooms require cancellation notice to refund the deposit as specified in cancellation & early checkout policy.
                                    </div>
                                    <div>
                                    </div>
                                </div>

                                <div className="p-content">
                                    <div class="justify-content-start room-title">
                                        <div>CANCELLATION AND EARLY CHECKOUT POLICY</div>
                                        <div class="bottom-border"></div></div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>Reservation must be cancelled 72 hours prior to the planned date of arrival. Reservations cancelled within 72 hours of the arrival date will incur a cancellation fee equivalent to one night's accommodation charge.
                                    </div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>Reservations made on promotional offers with 100% advance deposit required at the time of reservations are non cancellable, non-refundable and non amendable for entire length of stay.
                                    </div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>In case your plans change, ensure to inform in writing the cancellation/ amendments in plan to the Resort Reservation for a refund of your deposit. The guidelines in this regards as under:-
                                    </div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>The modification/cancellation request for bookings made online will be processed automatically through the Internet Booking Engine. Any refund due shall be done through the system in accordance with the applicable modification/cancellation rules. In case of rebooking, a rate difference may be payable for the change as per the rate applicable for the rebooked date/ room type and subject to availability.
                                    </div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>Any additional charges or rate difference for the modified reservation can be paid through the same payment mode/card as used for original reservation.
                                    </div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>Please note that if an online booking is modified or cancelled with the resort’s reservations Office/ resort’s sales office, it cannot be further modified or cancelled online.
                                    </div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>Certain room rates & types have restrictions and without benefits. In case you do not wish to opt for restricted rates & types, you can book the room(s) available on the resort’s website without
                                        benefits / restrictions.
                                    </div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>In case of early checkout, you are liable to pay for the entire confirmed reservation. If not sure of the policy, contact the hotel for clarification.
                                    </div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>Please note for reservation of more than one room in that case all rooms are determined as "reserved nights".
                                    </div>
                                    <div>
                                    </div>
                                </div>

                                <div className="p-content">
                                    <div class="justify-content-start room-title">
                                        <div>REFUND POLICY</div>
                                        <div class="bottom-border"></div></div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>Upon receiving the request for cancellation from the guest (as per cancellation/no show policy), the hotel will immediately contact the bank to handle the crediting process.
                                    </div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>The refund will be made through the account used by the customer  for reservation.
                                    </div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>In a normal scenario, the refunded amount shall reflect in the next billing cycle of the credit card. However, it could take around 30-45 business days for credit card billing, the actual crediting to take place depending on the bank’s practice.
                                    </div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>It would take around 7- 10 business days to reflect in bank your bank account if payment is done via UPI ID or credit card.
                                    </div>
                                    <div>
                                    </div>
                                </div>

                                <div className="p-content">
                                    <div class="justify-content-start room-title">
                                        <div>CHECK IN AND CHECK OUT</div>
                                        <div class="bottom-border"></div></div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>Normal check in time is 12:00 PM. For late arrivals, please call for instructions on the procedure for arrival after check in time.
                                    </div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>Check-out is at 10 AM.
                                    </div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>Early check-in and check-out is available by prior arrangement only i.e. subject to availability and prior intimation & confirmation only.
                                    </div>
                                    <div>
                                    </div>
                                </div>

                                <div className="p-content">
                                    <div class="justify-content-start room-title">
                                        <div>MODIFICATION</div>
                                        <div class="bottom-border"></div></div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>Any modification in the reservation is subject to availability at the time of making such a request.
                                    </div>
                                    <div>
                                    </div>
                                </div>

                                <div className="p-content">
                                    <div class="justify-content-start room-title">
                                        <div>CHILDREN AND INFANTS</div>
                                        <div class="bottom-border"></div></div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>Child (up to 8 years) can stay free of charge with parents without extra bed.
                                    </div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>Children between 8 years to 12 years of age will be charged extra.
                                    </div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>Children above 12 years of age will be treated as young adults and the prevalent reservation charges will be applicable and levied.
                                    </div>
                                    <div>
                                    </div>
                                </div>

                                <div className="p-content">
                                    <div class="justify-content-start room-title">
                                        <div>NON-ARRIVAL TO THE HOTEL (NO SHOW)</div>
                                        <div class="bottom-border"></div></div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>If you fail to arrive at the hotel on the arrival date the entire reservation will be cancelled automatically by the hotels and will be charged for the entire reservation.
                                    </div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>If you fail to check in on the first date but still continue your travel plan to stay at the hotel, please, urgently, contact us to keep the room for you for the rest of the nights. Otherwise as mentioned above, the entire reservation will be auto-cancelled and no refund admissible.
                                    </div>
                                    <div>
                                    </div>
                                </div>

                                <div className="p-content">
                                    <div class="justify-content-start room-title">
                                        <div>SHORTENED STAY (EARLY CHECK-OUT)</div>
                                        <div class="bottom-border"></div></div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>Shortened stay is subject to entire period charge whether or not you stay the entire period. If you know the change in your plan, please contact us at the earliest to minimize the charge by the hotel subject to cancellation policy.
                                    </div>
                                    <div>
                                    </div>
                                </div>

                                <div className="p-content">
                                    <div class="justify-content-start room-title">
                                        <div>SPECIAL REQUEST</div>
                                        <div class="bottom-border"></div></div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>Please note that Resort neither guarantees nor liable to admit any request for amendment in plan or any other special requests which shall be admissible subject to availability on arrival.
                                    </div>
                                    <div>
                                    </div>
                                </div>

                                <div className="p-content">
                                    <div class="justify-content-start room-title">
                                        <div>PAYMENT SECURITY</div>
                                        <div class="bottom-border"></div></div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>It is important to know that whenever you provide the personal details or credit card information, it is secured. Your credit card number, name, address, and telephone number are protected by the latest security technology.
                                    </div>
                                    <div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}
