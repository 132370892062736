import React, { Fragment } from "react";
import { HomeContent } from "../content/home";
import Backtotop from "../layouts/BacktoTop";
import Footer from "../layouts/Footer";
import Header from "../layouts/Header";
import SocialMediaIcon from "../layouts/SocialMediaIcon";

export default function Home() {
  return (
    <div>
      <Fragment >
        <Header />
        <HomeContent />
        <Backtotop/>
        <SocialMediaIcon/>
        <Footer/>
      </Fragment>
    </div>
  );
}
