import React, { useEffect } from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import border from '../../../assets/images/under/i3.png';
import img from '../../../assets/images/outdoor/g1.jpg';
import img1 from '../../../assets/images/outdoor/g2.jpg';
import img2 from '../../../assets/images/outdoor/g3.jpg';
import img3 from '../../../assets/images/outdoor/g4.jpg';
import img4 from '../../../assets/images/outdoor/g5.jpg';
import img5 from '../../../assets/images/outdoor/g6.jpg';

import { Tab, Nav } from "react-bootstrap";
import { useState } from "react";
import './style.css';
import DeluxRoom from "./deluxRoom";
import FriendRoom from "./friendRoom";
import SuiteRoom from "./suiteRoom";

export default function RoomsDetail(props) {
  useEffect(() => {
    document.body.scrollTop =0;
    document.documentElement.scrollTop = 0;
},[])

  const [tab, setTab] = useState(localStorage.getItem('activeRoom'))
  const [readmore, setRead] = useState(localStorage.getItem('readmore'))
 
  return (
    <section>
      <Header />
      <div className="games-section">
        <div className="p-3">
            <div className="booking-room">
            <div className="justify-content-center w-100 game-border">
              <div className="header-text">
                <div>Rooms</div>
              </div>
              <div className="d-flex justify-content-center header-img"><img src={border} /></div>
            </div>
            

              <div className="mt-4">
                <Tab.Container id="left-tabs-example" defaultActiveKey={tab}>
                  <div className="row">
                    <div className="col-sm-3">
                      <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                          <Nav.Link eventKey="deluxRoom">Economy Deluxe Rooms</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="friendRoom">Dormitory Friends Rooms</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                          <Nav.Link eventKey="suiteRoom">Premium Suite Rooms</Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </div>
                    <div className="col-sm-9">
                      <Tab.Content>
                        <Tab.Pane eventKey="deluxRoom">
                              <DeluxRoom />
                        </Tab.Pane>
                        <Tab.Pane eventKey="friendRoom">
                           <FriendRoom/>
                      </Tab.Pane>
                      <Tab.Pane eventKey="suiteRoom">
                      <SuiteRoom/>
                      </Tab.Pane>
                      
                      </Tab.Content>
                    </div>
                  </div>
                </Tab.Container>
              </div>
            </div>
            </div>
      </div>
      <Footer />
    </section>
  );
}
