import React, { Component } from 'react';
import { Link } from 'react-router-dom'

const navigationmenu = [
    {
        id: 1,
        linkText: 'Home',
        link: '/'
    },
    {
        id: 2,
        linkText: 'About',
        link: '/'
    },
    {
        id: 3,
        linkText: 'Room',
        link: '/rooms',
        child: true,
        submenu: [
            {
                id: 31,
                link: '/rooms',
                linkText: 'Economy Deluxe Rooms'
            },
            {
                id: 32,
                link: '/room',
                linkText: 'Dormitory Friends Rooms'
            },
            {
                id: 33,
                link: '/rooms',
                linkText: 'Premium Suite Rooms'
            },
        ]
    },
    {
        id: 8,
        linkText: 'Restaurant',
        link: '/restaurant'  
    },
    {
        id: 9,
        linkText: 'Bar & Spa',
        link: '/bar'  
    },
    {
        id: 4,
        linkText: 'Membership',
        link: '/membership'  
    },
    {
        id: 5,
        linkText: 'Play Area',
        link: '/games'
    },

    {
        id: 6,
        linkText: 'Gallery',
        link: '/gallery'
    },
    {
        id: 7,
        linkText: 'Contact',
        link: '/contact'
    },
    
]
class Mobilemenu extends Component {
    getNextSibling = function (elem, selector) {
        // Get the next sibling element
        var sibling = elem.nextElementSibling;
        // If there's no selector, return the first sibling
        if (!selector) return sibling;
        // If the sibling matches our selector, use it
        // If not, jump to the next sibling and continue the loop
        while (sibling) {
            if (sibling.matches(selector)) return sibling;
            sibling = sibling.nextElementSibling
        }
    }
    triggerChild = (e) => {
        let subMenu = '';
        subMenu = (this.getNextSibling(e.target, '.sub-menu') !== undefined) ? this.getNextSibling(e.target, '.sub-menu') : null;
        if (subMenu !== null && subMenu !== undefined && subMenu !== '') {
            subMenu.classList = subMenu.classList.contains('d-block') ? 'sub-menu' : 'sub-menu d-block';
        }
    }
    aboutUs(){
        window.scrollTo({
          top: 1300,
          behavior: "smooth"
      });
      }
    render() {
        
        return (
            <ul className="sigma-main-menu">
                {navigationmenu.length > 0 ? navigationmenu.map((item, i) => (
                    <li key={i} className={`menu-item px-4 ${item.child ? 'menu-item-has-children' : ''} `} onClick={this.triggerChild}>
                        {item.child ? <Link onClick={e => e.preventDefault()} to="/"> {item.linkText} </Link> : <Link to={item.link} onClick={()=>item.linkText==="About"?this.aboutUs():''}> {item.linkText} </Link>}
                        {item.child ?
                            <ul className="sub-menu" role="menu">
                                {item.submenu.map((sub_item, i) => (
                                    <li key={i} className={`menu-item ${sub_item.child ? 'menu-item-has-children' : ''} `}>
                                        {sub_item.child ? <Link onClick={e => e.preventDefault()} to="/"> {sub_item.linkText} </Link> : <Link to="/rooms"> {sub_item.linkText} </Link>}
                                        {sub_item.submenu ?
                                            <ul className="sub-menu">
                                                {sub_item.submenu.map((third_item, i) => (
                                                    <li className="menu-item" key={i}><Link
                                                        to={third_item.link}>{third_item.linkText}</Link>
                                                    </li>
                                                ))}
                                            </ul> : null}
                                    </li>
                                ))}
                            </ul>
                            : null
                        }
                    </li>
                )) : null}
            </ul >
        );
    }
}

export default Mobilemenu;