import { Dropdown } from "bootstrap";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import bg from '../../../assets/images/bg/bg_d_1.jpg';
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import border from '../../../assets/images/under/i3.png';
import img from '../../../assets/images/facility/f4.jpg';
import img1 from '../../../assets/images/facility/b4.jpg';
import StarRateIcon from '@mui/icons-material/StarRate';
import { useMediaQuery } from "react-responsive"; 
import { Fade } from "react-awesome-reveal";
import { useState, useEffect } from "react";
import './style.css';

export default function Restaurant() {

  
useEffect(() => {
  document.body.scrollTop =0;
  document.documentElement.scrollTop = 0;
},[])

const isDesktop = useMediaQuery({
  query: "(min-width: 992px)",
});

  
  const handleChange = (event) => { };

  return (
    <section>
      <Header />
      <div className="games-section">
        {/* style={{ backgroundImage: "url(" + bg + ")" }} */}
        <div className="p-3">
            <div className="booking-room">
            <div className="justify-content-center w-100 game-border">
              <div className="header-text">
                <div>Restaurant</div>
              </div>
              <div className="d-flex justify-content-center header-img"><img src={border} /></div>
            </div>
            <div className="d-flex justify-content-center p-3 note-card">
              <p className="p-3 sub-text">
                 The restaurant offers a wide range cuisines like Chinese, South Indian,
                  North Indian, Tandoori items that will leave a long lasting impression on your taste buds.
                   Enjoy your food along with a panoramic view of hills.
              </p>
              <p className="p-3 sub-text">
              Good food is an Art and chef an Artist. Innovating that perfect blend of ingredients,
              flavour, texture is a challenge that needs talent, care, time, dedication and experience.
                Our chefs deliver excellence and being attentive to every detail and treasure the dishes,
                presentation and care.
            </p></div>
            
            
              <div className="row mt-4">
                <div className="col-sm-6 col-lg-6 col-md-6"><div className=" rest-card">
                    <div className="restaurant-title">Moonwalk's Restaurant</div> <div className="bottom-border"></div>
                    <div className="restaurant-dis">
                    <div className="justify-content-center d-flex restaurant-time-h pt-2 mt-3 py-2"> A Traditional Taste of Kongu Belt</div>
                    <div class="sub-text-room">A dazzling variety of South Indian dishes with all types of Kongu Belt cuisines will be served and Chinese varieties are also available at our kitchen.</div>
                    
                    {/* <div className="justify-content-center d-flex sub-text-room"> * Grill Chicken is available.</div> */}
                    {isDesktop?<div className="justify-content-start w-100 d-flex pt-0">
                <div  className="restaurant-time-sec px-3">
                      <div className="restaurant-time-h"><b>Dine-In Timings Opens from</b></div>
                      <div className="restaurant-time-l pt-3">
                        <ul>
                          <li>Breakfast - 8:00AM to 10:00AM</li>
                          <li>Lunch - 1:00PM to 3:00PM</li>
                          <li>Dinner - 7:30PM to 10:00PM</li>
                          <li>Food preparation time may take 15-30 minutes based on order please do wait for your Yummy Food</li>
                        </ul>
                      </div>
                      <div className="restaurant-time-h pt-2">Note:-</div>
                      <div className="restaurant-time-p pt-2"><StarRateIcon className="blue-icon"/>Foods will not be served to rooms at any cost.</div>
                      <div className="restaurant-time-p pt-2"><StarRateIcon className="blue-icon"/>Dine-In will be closed prior to these above-mentioned timings.</div>
                      <div className="restaurant-time-p pt-2"><StarRateIcon className="blue-icon"/>Strictly Outside Foods are not allowed</div>
                    </div>
                </div>:''}
                    </div>
                    </div>
                </div>
                <div className="col-sm-6 col-lg-6 col-md-6">
                    <div className="rest-image">
                        <img src={img} alt=" image not found"/>
                    </div>
                </div>
                {!isDesktop?<div className="col-12 justify-content-start w-100 d-flex pt-4">
                <div  className="restaurant-time-sec px-3">
                      <div className="restaurant-time-h"><b>Dine - In timings are</b></div>
                      <div className="restaurant-time-l pt-3">
                        <ul>
                          <li>Breakfast - 8AM to 10AM</li>
                          <li>Lunch - 1PM to 3PM</li>
                          <li>Dinner - 7:30PM to 10PM</li>
                          <li>Time for food preparation time may take 15-30 minutes based on order </li>
                        </ul>
                      </div>
                      <div className="restaurant-time-h pt-2">Note:-</div>
                      <div className="restaurant-time-p pt-2"><StarRateIcon className="blue-icon"/>Foods will not be served to rooms at any cost.</div>
                      <div className="restaurant-time-p pt-2"><StarRateIcon className="blue-icon"/>Dine - In will closed prior to these above-mentioned timings.</div>
                      <div className="restaurant-time-p pt-2"><StarRateIcon className="blue-icon"/>Strictly Outside Foods are not allowed.</div>
                    </div>
                </div>:''}
                <div className="col-sm-6 col-lg-6 col-md-6 pt-5">
                    <div className="rest-image">
                        <img src={img1} alt=" image not found"/>
                    </div>
                </div>
                <div className="col-sm-6 col-lg-6 col-md-6 pt-5"><div className="">
                    <div className="restaurant-title">Open Theater</div> <div className="bottom-border"></div>
                    <div className="restaurant-dis pt-5 sub-text-room">
                      Our guests will be served and supplied with our dishes outside the  restaurant with nature feel and Open Theater place if they pre order the dishes.
                    </div>
                   </div>
                </div>
              </div>
            </div>
            </div>
      </div>
      <Footer />
    </section>
  );
}
