import logo from './logo.svg';
import './App.css';
import Navigation from './navigation/Router';
import './app/assets/css/style.css'
import './app/assets/css/custom.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <Navigation/>
  );
}

export default App;
