import { Dropdown } from "bootstrap";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import bg from '../../../assets/images/bg/bg_d_1.jpg';
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import border from '../../../assets/images/under/i3.png';
import img from '../../../assets/images/facility/f1.jpg';
import img1 from '../../../assets/images/facility/f2.jpg';
import img2 from '../../../assets/images/facility/f3.jpg';
import img3 from '../../../assets/images/facility/f4.jpg';
import img4 from '../../../assets/images/facility/f5.jpg';
import img5 from '../../../assets/images/facility/f6.jpg';

import img20 from '../../../assets/images/facility/f1.jpg';
import img6 from '../../../assets/images/facility/f2.jpg';
import img15 from '../../../assets/images/facility/f3.jpg';
import img16 from '../../../assets/images/facility/f4.jpg';
import img17 from '../../../assets/images/facility/f5.jpg';
import img18 from '../../../assets/images/facility/f6.jpg';
import img19 from '../../../assets/images/facility/f7.jpg';
import img7 from '../../../assets/images/facility/f8.jpg';
import img8 from '../../../assets/images/facility/f9.jpg';
import img9 from '../../../assets/images/facility/f10.jpg';
import img10 from '../../../assets/images/facility/f11.jpg';
import img11 from '../../../assets/images/facility/f12.jpg';
import img12 from '../../../assets/images/facility/f13.jpg';
import img13 from '../../../assets/images/facility/f14.jpg';
import img14 from '../../../assets/images/facility/f15.jpg';
import imgs from '../../../assets/images/facility/b1.jpg';
import imgs1 from '../../../assets/images/facility/b2.jpg';
import imgs2 from '../../../assets/images/facility/b3.jpg';
import imgs3 from '../../../assets/images/facility/b4.jpg';
import imgs4 from '../../../assets/images/facility/b5.jpg';
import imgs5 from '../../../assets/images/facility/b6.jpg';
import imgs6 from '../../../assets/images/facility/b7.jpg';
import imgs7 from '../../../assets/images/facility/b8.jpg';
import imgs8 from '../../../assets/images/facility/b9.jpg';
import im1 from '../../../assets/images/facility/f16.jpg';
import im2 from '../../../assets/images/facility/fc1.jpeg';
import im3 from '../../../assets/images/facility/fc3.jpeg';
import im4 from '../../../assets/images/facility/fc5.jpeg';
import im5 from '../../../assets/images/facility/fc4.jpeg';
import im6 from '../../../assets/images/facility/mb-1.jpg';

import { Tab, Nav } from "react-bootstrap";
import { Fade } from "react-awesome-reveal";
import { useState } from "react";
import './style.css';

export default function Gallery() {
  const [indoor, setIndoor] = useState([
    { id: 1, img: img, title: "Musical chairs", delay: 700},
    { id: 2, img: img1, title: "Musical chairs", delay: 800},
    { id: 3, img: img2, title: "Musical chairs", delay: 900 },
    { id: 4, img: img3, title: "Musical chairs",delay: 1000 },
    { id: 5, img: img4, title: "Musical chairs", delay: 1100 },
    { id: 6, img: img5, title:"Musical chairs", delay: 1200},
    { id: 7, img: img6, title:"", delay: 700},
    { id: 8, img: img7, title:"", delay: 800},
    { id: 9, img: img8, title:"", delay: 900},
    { id: 10, img: img9, title:"", delay: 1000},
    { id: 11, img: img10, title:"", delay: 1100},
    { id: 12, img: img11, title:"", delay: 1200},
    { id: 13, img: img12, title:"", delay: 1300},
    { id: 14, img: img13, title:"", delay: 1400},
    { id: 15, img: img14, title:"", delay: 1500},
    { id: 16, img: img15, title:"", delay: 700},
    { id: 17, img: img16, title:"", delay: 800},
    { id: 18, img: img17, title:"", delay: 1400},
    { id: 19, img: img18, title:"", delay: 1500},
    { id: 20, img: img19, title:"", delay: 700},
    { id: 21, img: img20, title:"", delay: 800},
    { id: 22, img: imgs, title:"", delay: 1300},
    { id: 23, img: imgs1, title:"", delay: 1400},
    { id: 24, img: imgs2, title:"", delay: 1500},
    { id: 25, img: imgs3, title:"", delay: 700},
    { id: 26, img: imgs4, title:"", delay: 800},
    { id: 27, img: imgs5, title:"", delay: 1400},
    { id: 28, img: imgs6, title:"", delay: 1500},
    { id: 29, img: imgs7, title:"", delay: 700},
    { id: 30, img: imgs8, title:"", delay: 800},
    { id: 30, img: im1, title:"", delay: 800},
    { id: 29, img: im2, title:"", delay: 700},
    { id: 30, img: im3, title:"", delay: 800},
    { id: 30, img: im4, title:"", delay: 800},
    { id: 30, img: im5, title:"", delay: 800},
    { id: 30, img: im6, title:"", delay: 800},
    

])
  return (
    <section>
      <Header />
      <div className="games-section">
        {/* style={{ backgroundImage: "url(" + bg + ")" }} */}
        <div className="p-3">
            <div className="booking-room">
            <div className="justify-content-center w-100 game-border">
              <div className="header-text">
                <div>Gallery</div>
              </div>
              <div className="d-flex justify-content-center header-img"><img src={border} /></div>
            </div>
            

              <div className="pt-4">
                
                  <div className="">
                    <div className="col-sm-12">
                          <div className="row">{indoor.map((item)=>(
                            <div className="col-sm-4 pb-3">
                              <Fade delay={item.delay} direction={"down"}>
                              <div className="games-cards">
                                <div className="games-img"><img src={item.img}/></div>
                                {/* <div className="games-cards-text">{ item.title }</div> */}
                                </div>
                                </Fade>
                            </div>))}
                          </div> 
                    </div>
                  </div>
              </div>

            </div>
            </div>
      </div>
      <Footer />
    </section>
  );
}
