import React, { Component, Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom'
import Banner from'./Banner';
import AboutUs from './AboutUs';
// import Restaurant from './Restaurant';
// import PlayArea from './PlayArea';
// import Gallery from './Gallery';
import BookNow from './BookNow';
import Rooms from './Rooms';
import Facility from './Facility';
import Insta from '../instaPage';
import FeedBack from './FeedBack';
import Customer from './Customer';
import ReactChatBot from '../../layouts/ChatBot';
import Map from './Map';
import countapi from 'countapi-js';


export function HomeContent() {
  useEffect(()=>{
    debugger

    countapi.get('moonwalkresorts.in','visits').then((result) => { console.log('my website', result.value) });
    countapi.visits().then((result) => {
      console.log('Welcome to Moonwalkresorts',result.value);
    });
  },[])
        return (
          <div>
            <Banner/>
            <BookNow />
            <AboutUs />
            <Rooms/>
            <Facility />
            <FeedBack />
            <Customer/>
            <Insta/> 
            <Map/>
            {/* <ReactChatBot/> */}
            {/* <Restaurant/>
            <PlayArea/>
            <Gallery/> */}
       
          </div>
        )
}
