import ChatBot from 'react-simple-chatbot';
import {useState, useRef, useEffect} from 'react';

export default function ReactChatBot() {
  const [show, setShow] = useState(false);
  const target = useRef(null);


  const steps = [
    {
      id: '0',
      message: 'Welcome To Moonwalk Resorts',
      trigger: '2',
    },
    {
      id: '1',
      message: 'Facilities offered 1.Premium Suite Rooms, 2.Dormitory Friends Rooms, 3.Economy Deluxe Rooms',
      trigger:'5'
    },
    {
      id: '2',
      message: 'What is your name?',
      trigger: '3',
    },
    {
      id: '3',
      user: true,
      trigger: '4',
    },
    {
      id: '4',
      message: 'Hi {previousValue},',
      trigger: '1',
    },
    {
      id: '5',
      component: (
        <div> For more Enquiry. Please contact us via whatsapp <a href="https://api.whatsapp.com/send?phone=+919445526699" target="_blank" className='w-text'>94455 26699</a> </div>
      ),
      asMessage: true,
      end: true,
    },
  ];
  

  return (<div title="chat-bot">
     <ChatBot steps={steps} />
  </div>)
}