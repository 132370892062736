import React, { useEffect } from "react";
import border from '../../../assets/images/under/i3.png';
import Reveal, { Fade } from "react-awesome-reveal";

import img1 from '../../../assets/images/facility/b6.jpg';
import img2 from '../../../assets/images/facility/b7.jpg';
import img3 from '../../../assets/images/facility/b4.jpg';
import img4 from '../../../assets/images/facility/b5.jpg';
import img5 from '../../../assets/images/facility/b3.jpg';

import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import RoomCarosual from "../rooms/carosual";
import "./style.css"

const carosual_data = [
  { img: img5, id: 1 },
  { img: img1, id: 2 },
  { img: img2, id: 3 },
  { img: img3, id: 4 },
  { img: img4, id: 5 },]

export default function ContactUs() {
  useEffect(() => {
    document.body.scrollTop =0;
    document.documentElement.scrollTop = 0;
},[])
  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    dots: false,
    responsive: [{
      breakpoint: 992,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
      },
    },
    ],
  }
  return (
    <div>
      <Header/>
    <section className="mt-5">
      <div className="container">
      <div className="d-flex justify-content-center about-title mt-5">
        
        <h2 className="mt-5 pt-3">Contact Us</h2>
      </div>
      <div className="d-flex justify-content-center header-img"><img src={border} /></div>
      <div className="contact-card">
          <div className="row m-3 mt-3 mb-5">
           
        <div className="col-12 col-sm-6 col-md-6 col-lg-6 h-100 p-0 m-0">
              <Fade direction={"left"} delay={800}>
                <RoomCarosual data={carosual_data} name={"contact"} silde={1}/>
              </Fade>
        </div>
        <div className="col-12 col-sm-6 col-md-6 col-lg-6 mt-2">
                <Fade direction={"right"} delay={900}>
                  <div className="row">
                  <div className="col-6 pt-4">
                    <div><input type="text" className="f-control" placeholder="Enter FirstName"/></div>
                  </div>
                  <div className="col-6 pt-4">
                    <div><input type="text" className="f-control" placeholder="Enter LastName"/></div>
                  </div>
                  </div>
                  <div className="col-12 pt-4">
                    <div><input type="text" className="f-control" placeholder="Enter Email"/></div>
                  </div>
                  <div className="col-12 pt-4">
                    <div><textarea type="text" rows={1} id="myTextarea" className="f-t-control" placeholder="Enter Message"/></div>
                  </div>
                  <div className="col-12 w-100 d-flex pt-4">
                    <div className="btn-contact justify-content-center"><button className="submit-btn">Submit</button></div>
                  </div>
          </Fade>
        </div> 
      </div>
      </div>
      </div>
      </section>
      <Footer/>
      </div>
  );
}
