import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import './style.css';
import logo from '../../assets/images/mw_logo.png';

import Lottie from "../content/common/LottieAnimation";
import loading from "../content/common/loading.json";

function Footer() {
        return (
            <Fragment>
                {/* <Backtotop /> */}
                <div className="row justify-content-center">
                    <div className="col-8">
                        <div className="loading-logo justify-content-center w-100"><img src={logo} alt=""/></div>
                        <div  className="loading-img"><Lottie lotti={loading} loop={true} height={80} width={80} /></div>
                    </div>
                </div>
            </Fragment>
        );
    }
export default Footer;