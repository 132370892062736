import React, { useEffect } from "react";

import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import StarRateIcon from '@mui/icons-material/StarRate';
import border from '../../../assets/images/under/i3.png';

import "./style.css"

export default function Terms() {
    useEffect(() => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }, [])

    return (
        <div>
            <Header />
            <section className="mt-5">
                <div className="container">
                    <div className="d-flex justify-content-center about-title terms-title mt-5">
                        <div><h2 className="mt-5 pt-3">TERMS AND CONDITIONS</h2></div>   
                    </div>
                    <div className="d-flex justify-content-center header-img"><img src={border} /></div>
                    <div className="contact-card">
                        <div className="row mt-3 mb-5">

                            <div className="col-12">
                                <div className="p-content">
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>The terms and conditions set out below (“Conditions” or “terms of service ”) apply to your use of the Site including the use of the information services offered on the Site.
                                    </div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>By using the Site, you are agreeing to comply with and be bound by the Terms of Service. If you do not agree to the Terms of Service of this site without any modifications. If you do not accept these Conditions, you must refrain from using the Site.
                                    </div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>The terms “we” and “us” refers to Moonwalk resorts and its affiliates, and the term “you” refers to you, the user of the Site.
                                    </div>
                                    <div>
                                    </div>
                                </div> 

                                <div className="p-content">
                                    <div class="justify-content-start room-title">
                                        <div>SITE</div>
                                        <div class="bottom-border"></div></div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>We provide information regarding the services offered by Moonwalk Resorts such as availability of rooms, reservations etc. on this Site.
                                    </div>
                                    <div>
                                    </div>
                                </div>

                                <div className="p-content">
                                    <div class="justify-content-start room-title">
                                        <div>USE OF WEBSITE</div>
                                        <div class="bottom-border"></div></div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>You warrant that you are at least 18 years of age, possess the legal authority by your acceptance of these Conditions and to use the Site in accordance with such Conditions. The site is available only to individuals and entities that can form legally binding contracts under applicable law. If you are under the age of eighteen, you may contact the hotel directly for assistance.
                                    </div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>The Site and the materials located on or through the Site are provided by us for informational purposes only, with the understanding that we are by the provision of these materials not engaged in the rendering of legal or other professional advice or service. Commercial use of this site is strictly prohibited unless you obtain prior written consent from Moonwalk Resorts. You may only use this site to make legitimate reservations or purchases. You are responsible for maintaining the secrecy of your passwords, login and account information. You will be financially accountable for all uses of the site by you and anyone using your password and login information. The information contained in or through the Site is based upon sources believed to be accurate and reliable; and we have exercised reasonable care to assure the accuracy of the information. However, we make no representation or warranty as to such accuracy. These materials were prepared for us by personnel of our owned and managed hotels and others. They are made available to others as an accommodation only. By providing these materials, we do not intend to exercise any direction, oversight or control over those policies and procedures. We do not guarantee that the Site will operate continuously or without interruption or be error free. In addition, We may also suspend or discontinue any aspect of the Site at any time without being liable for any direct or indirect loss as a result of such action.
                                    </div>
                                    <div>
                                    </div>
                                </div>

                                <div className="p-content">
                                    <div class="justify-content-start room-title">
                                        <div>INTELLECTUAL PROPERTY</div>
                                        <div class="bottom-border"></div></div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>You acknowledge and agree that we and our licensors retain ownership of all intellectual property rights (including applicable copyrights, trademarks and other proprietary rights) of any kind on the Site, and our websites, emails, Sites, mobile sites, applications, other products and/or services. We are not granting any license to you under any of those intellectual property rights by virtue of these Terms of Service. You further acknowledge and agree that we retain ownership and control over the "look and feel" and substance of our tools, widgets, buttons, applications and the like. We reserve all rights that are not explicitly granted to you in under these Terms of Service.
                                    </div>
                                    <div>
                                    </div>
                                </div>

                                <div className="p-content">
                                    <div class="justify-content-start room-title">
                                        <div>TRADEMARK</div>
                                        <div class="bottom-border"></div></div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>Our trademarks, logos, service marks, images, trade names, designs, page headers, button icons, scripts and other distinctive branding features constitute our intellectual property and may not be copied, imitated, or used, in whole or in part. You agree not to infringe any intellectual property rights when you use the Site and that you will indemnify us for any claims which may arise on us in this regard.
                                    </div>
                                    <div>
                                    </div>
                                </div>

                                <div className="p-content">
                                    <div class="justify-content-start room-title">
                                        <div>COPYRIGHT</div>
                                        <div class="bottom-border"></div></div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>The Site, including each of its modules, is the copyrighted property. The information, text, graphics, images, photographs, videos, sounds, links and all other information and software published or otherwise contained in the Site (“Information”) are either owned exclusively by or licensed by Moonwalk Resorts and except as specifically provided in these Conditions may not be copied, distributed, displayed, reproduced or transmitted, in any form or by any means whether electronic, mechanical, photocopying, recording, or otherwise, without the prior written approval of Moonwalk Resorts.
                                    </div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>Without limitation you may not, without such approval from Moonwalk Resorts create derivative works from any part of the Site or commercialise any Information, products or services obtained from any part of the Site. Information procured from a third party may be the subject of copyright owned by that third party. Unauthorized use of the Site and/or the materials contained on the Site may violate applicable copyright, trademark or other intellectual property laws or other laws.
                                    </div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>You must retain all copyright and trademark notices, including any other proprietary notices, contained in the materials. The use of such materials on any other web site or in any environment of networked computers is strictly prohibited.
                                    </div>
                                    <div>
                                    </div>
                                </div>

                                <div className="p-content">
                                    <div class="justify-content-start room-title">
                                        <div>LIABILITY DISCLAIMER & EXCLUSION</div>
                                        <div class="bottom-border"></div></div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>Neither us nor any of our subsidiaries, affiliates, agents, representatives or licensors shall be responsible for any direct, indirect, special, incidental or consequential loss or damage, punitive or similar damages including without limitation, loss or damage by way of loss of profits, loss of business opportunity, business interruption or loss of information however arising and whether in contract, tort or otherwise, which you or anyone else may suffer in connection with or arising out of, however arising and whether in contract, tort or otherwise, which you may suffer in connection with or arising out of your access or use or your inability to access or use of the Site or any Linked Web Site; or your use of or reliance on Information or any other material accessed through the Site including via any Linked Web Site;
                                    </div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>These Conditions do not purport to exclude liability arising by any applicable law if, and to the extent, such liability cannot be lawfully excluded, however, to the extent permitted by law, all warranties, terms or conditions which would otherwise be implied into these Conditions are hereby excluded.
                                    </div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>Where applicable law implies any warranty, term or condition, and that law prohibits exclusion or modification of the application of or the liability of Moonwalk Resorts, any such warranty, term or condition, then the liability of Moonwalk Resorts shall include
                                    liability for that warranty, term or condition but such liability will be limited for a breach of that warranty, term or condition, if the breach relates to services, the supply of the services again or payment of the cost of having the services supplied again; and if the breach relates to products and the products can be repaired, repair of such products or payment of the costs of having the products repaired, but otherwise, replacement of the products or the supply of equivalent products or payment of the cost of replacing the products or of acquiring equivalent products.
                                    </div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>We are not responsible for, and shall have no liability for:
                                    </div>
                                    <div class="sub-text-room">
                                    (i) telephone, mobile, electronic, network, internet, computer, hardware or software program malfunctions, delays, or failures;
                                    </div>
                                    <div class="sub-text-room">
                                    (ii) for any injury, loss or damage to your computer or interception or use of credit card information, related to or resulting from use of the site or any sites, services, or materials linked or relating thereto;
                                    </div>
                                    <div class="sub-text-room">
                                    (iii) any incorrect information, whether caused by other users or by any of the equipment, software, or programming associated with or utilized in the site or as the result of technical or human error which may occur in the processing of information regarding the site.
                                    </div>
                                    <div>
                                    </div>
                                </div>

                                <div className="p-content">
                                    <div class="justify-content-start room-title">
                                        <div>OTHER MONITORING PROGRAMS</div>
                                        <div class="bottom-border"></div></div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>For security purposes and to ensure the availability of the Site, we also use software programs and tools to monitor network traffic, to identify unauthorised attempts to upload or change information, or otherwise cause damage to the Site.
                                    </div>
                                    <div>
                                    </div>
                                </div>

                                <div className="p-content">
                                    <div class="justify-content-start room-title">
                                        <div>HOTEL RESERVATIONS</div>
                                        <div class="bottom-border"></div></div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>Moonwalk Resorts provides you with information relating to availability of rooms at Moonwalk Resorts on the Site and also assists you in making legitimate reservations.
                                    </div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>The Site contains details of hotel charges for services it offers and room rates (including any available special offers) for hotels owned or managed by Moonwalk Resorts.
You warrant that you are at least 18 years of age, possess the legal authority to enter into the legal agreement constituted by your acceptance of these conditions and to use the Site in accordance with such conditions.
                                    </div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>You agree to be financially responsible for your use of the Site including without limitation for all reservations made by you or on your account for you, whether authorised by you or not. For any reservations or other services for which fees may be charged you agree to abide by the terms or conditions of supply including without limitation payment of all moneys due under such terms or conditions.
                                    </div>
                                    <div>
                                    </div>
                                </div>

                                <div className="p-content">
                                    <div class="justify-content-start room-title">
                                        <div>IDENTITY PROOF</div>
                                        <div class="bottom-border"></div></div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>In keeping with Government regulations, we request you to carry your photo identity which is to be presented at the time of check-in.
                                    </div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>If you are a Foreign national you are required to present your valid passport and visa. If you are an Indian national you can present any government issued photo identity and address proof such as driving license, passport, Aadhar card or voter’s ID card.
                                    </div>
                                    <div>
                                    </div>
                                </div>
                                
                                <div className="p-content">
                                    <div class="justify-content-start room-title">
                                        <div>PAYMENTS</div>
                                        <div class="bottom-border"></div></div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>You are responsible for paying all payments and applicable taxes associated with the reservations or other services rendered by you from Moonwalk Resorts through the payment method provided on the Site itself.
                                    </div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>All bookings must be guaranteed at the time of reservation by a credit card/debit card. All major credit cards/debit cards are accepted.
                                    </div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>You also authorise us to collect and store the details of your payment, along with other related transaction information. We may also have to share this information with our bankers and payment gateways, etc.
                                    </div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>If the payment method specified by you fails, then:
                                    </div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>you agree to pay all amounts due from you upon demand and reimburse us for all reversals, charge-backs, claims, fees, fines, penalties and other liability incurred by us (including costs and related expenses) that were caused by or arising out of payments that you authorised or accepted,
we may collect payments owed using other collection mechanisms (this includes charging other payment methods on file with us);
                                    </div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>You agree to submit any disputes regarding any charge to your account in writing to us within thirty (30) days of such charge, otherwise such dispute will be waived and such charge will be final and not subject to challenge.
                                    </div>
                                    <div>
                                    </div>
                                </div>

                                <div className="p-content">
                                    <div class="justify-content-start room-title">
                                        <div>SECURITY & NON CONFIDENTIALITY</div>
                                        <div class="bottom-border"></div></div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>Moonwalk Resorts cannot assure  the security or confidentiality of any information which you transmit to Moonwalk Resorts. Accordingly, any information which you transmit to Moonwalk Resorts is transmitted at your own risk.
                                    </div>
                                    <div>
                                    </div>
                                </div>

                                <div className="p-content">
                                    <div class="justify-content-start room-title">
                                        <div>PRIVACY</div>
                                        <div class="bottom-border"></div></div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>Moonwalk Resorts respects the privacy of the users of this Site. Your use of the Site is subject to Moonwalk Resorts Privacy Policy. If you would like to view the privacy practices please review our Privacy Policy available on the Site. By using our site, you consent to your personal information being processed as set out in our Privacy Policy.
                                    </div>                                   
                                    <div>
                                    </div>
                                </div>

                                <div className="p-content">
                                    <div class="justify-content-start room-title">
                                        <div>GOVERNING LAW AND DISPUTE RESOLUTION</div>
                                        <div class="bottom-border"></div></div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>These Terms of Service shall be governed by the laws of the Republic of India without giving effect to any principles that may provide the application of the law of another jurisdiction. 
                                    Notwithstanding the foregoing, we may seek injunctive or other equitable relief to protect our rights in any court of competent jurisdiction.
                                    </div>                                   
                                    <div>
                                    </div>
                                </div>

                                <div className="p-content">
                                    <div class="justify-content-start room-title">
                                        <div>GENERAL</div>
                                        <div class="bottom-border"></div></div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>A failure on our part to exercise or enforce any right or provision of these Terms of Service does not constitute a waiver of such right or provision. If any provision of these Terms of Service is found by a court of competent jurisdiction to be invalid, it shall not invalidate the rest of the Terms of Service which shall remain in full force and effect, and we shall try to give effect to the intention as reflected in the provision. We may assign the agreement between us pursuant to the Terms of Service in whole or in part in its sole discretion without your consent and without notice. Nothing in this Agreement shall prevent us from complying with the law and applicable regulations.
                                    </div>                                   
                                    <div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}
