import { Dropdown } from "bootstrap";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import bg from '../../../assets/images/bg/bg_d_1.jpg';
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import border from '../../../assets/images/under/i3.png';
import img from '../../../assets/images/facility/f7.jpg';
import img1 from '../../../assets/images/original/room/spa.jpg';
import img2 from '../../../assets/images/facility/f5.jpg';
import { Fade } from "react-awesome-reveal";
import { useState, useEffect } from "react";
import './style.css';

export default function BarSpa() {

  
useEffect(() => {
  document.body.scrollTop =0;
  document.documentElement.scrollTop = 0;
},[])
  
  const handleChange = (event) => { };

  return (
    <section>
      <Header />
      <div className="games-section">
        {/* style={{ backgroundImage: "url(" + bg + ")" }} */}
        <div className="p-4">
            <div className="booking-room">
            <div className="justify-content-center w-100 game-border">
              <div className="header-text">
                <div>Bar and Club</div>
              </div>
              <div className="d-flex justify-content-center header-img"><img src={border} /></div>
            </div>
           
            
            
              <div className="row mt-4">
                <div className="col-sm-6 col-lg-6 col-md-6"><div className="container rest-card">
                    <div className="card-justify">BAR - PARADISE</div> <div className="bottom-border"></div>
                    <div className="pt-4 card-justify">CLUB - HAPPY VALLEY RECREATION CLUB</div> <div className="bottom-border"></div>
                    <div className="restaurant-dis pt-4">
                    <div className="justify-content-center d-flex card-justify pb-3">We have a FL-2 LICENSED BAR CUM CLUB</div>
                    
                    <div className="pt-3 pb-3 note-card">We serve all kinds of domestic liquors.
                      We serve mocktails and cocktails. We have private smoking rooms.</div>
                    </div>
                    </div>
                </div>
                <div className="col-sm-6 col-lg-6 col-md-6">
                    <div className="rest-image">
                        <img src={img} alt=" image not found"/>
                    </div>
                </div>
                <div className="col-sm-6 col-lg-6 col-md-6 pt-3">
                    <div className="rest-image">
                        <img src={img2} alt=" image not found"/>
                    </div>
                </div> 
                <div className="col-sm-6 col-lg-6 col-md-6 pt-4">
                <div className="restaurant-title">Open Bar</div> <div className="bottom-border"></div>
                </div>
                <div className="d-flex justify-content-center p-3 note-card"><p className="p-0 sub-text m-no">
              Bar contact no : +91 9486976699
            </p></div>

                <div className="justify-content-center w-100 game-border mt-3">
              <div className="header-text">
                <div>SPA</div>
              </div>
              <div className="d-flex justify-content-center header-img"><img src={border} /></div>
            </div>
               
                <div className="col-sm-6 col-lg-6 col-md-6 pt-5">
                <div className="restaurant-title">Mountain Spa - A Ayurvedic Spa</div> <div className="bottom-border"></div>
                 <div className="container">
                    
                    <div className="restaurant-dis pt-4">
                    <p className="sub-text"><h5><b>"Our Spa is Leased"</b></h5></p>
                    <div className="restaurant-dis facility-name">
                     <b>Two types of massage</b>
                    </div>
                    <ul className="facility-name">
                      <li className="px-2"> 45 Minutes and 1 Hour</li>
                    </ul>
                    <div className="restaurant-dis pt-2 facility-name">
                    For futher details and enquiries about spa you can contact<b> name : Prabu & Siddiq</b>
                    </div>
                      
                   </div>
                    {/* <p className="facility-name">For any enquiries about Massage and Ayurvedic Treatment</p> */}

                    <p className="facility-name px-2">Guests can contact and prebook with :<a href="tel:+91 80722 20280"><b>&nbsp;+91 80722 20280</b></a>, <a href="tel:+91 96267 77548"><b>&nbsp;+91 96267 77548</b></a></p>
                      
                    </div>
                    
                </div>
                 
                <div className="col-sm-6 col-lg-6 col-md-6 pt-5">
                    <div className="rest-image">
                        <img src={img1} alt=" image not found"/>
                    </div>
                </div>
                
              </div>
            </div>
            </div>
      </div>
      <Footer />
    </section>
  );
}
