import { Dropdown } from "bootstrap";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import bg from '../../../assets/images/bg/bg_d_1.jpg';
import Reveal, { Fade } from "react-awesome-reveal";

import { keyframes } from '@emotion/react';
const customAnimation = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 200px, 0);
  }
to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;
function AnimatedComponent({ children }) {
  return <Reveal keyframes={customAnimation}>{children}</Reveal>;
}

export default function BookNow() {
  const handleChange = (event) => {};

  return (
    <div>
      <AnimatedComponent>
        {/* style={{ backgroundImage: "url(" + bg + ")" }} */}
    <div className="container">
        <div className="container booking-room mt-5">
      <div className="row">
        <div className="col-sm-3">
          <label for="datepicker1" className="form-label">Check In</label>
          <input
            type="date"
            className="form-control"
              id="datepicker1"
              value
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="col-sm-3">
          <label for="datepicker2" className="form-label">Check Out</label>
          <input
            type="date"
            className="form-control"
              id="datepicker2"
              value
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="col-sm-1">
            <div className="field-icon-wrap">
            <label for="datepicker2" className="form-label">Room</label>
            <div className="icon-section1">
              <ExpandMoreIcon/>
            </div>
            <select className="form-control">
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option>6</option>
              </select>
              </div>
        </div>
        <div className="col-sm-1">  
          <div className="field-icon-wrap">
            <label for="datepicker2" className="form-label">Adult</label>
            <div className="icon-section1">
              <ExpandMoreIcon/>
            </div>
            <select className="form-control">
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option>6</option>
              </select>
              </div>
        </div>
          <div className="col-sm-1">
            <div className="field-icon-wrap">
            <label for="datepicker2" className="form-label">Children</label>
            <div className="icon-section1">
              <ExpandMoreIcon/>
            </div>
            <select className="form-control">
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option>6</option>
              </select>
              </div>
        </div>
        <div className="col-sm-3 justify-content-center btn-section">
            <button className="custom-btn px-5"> Check Availabilty <ChevronRightIcon className="icons"/></button>
        </div>
      </div>
      </div>
      </div>
 </AnimatedComponent>
      </div>
  );
}
