import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import $ from "jquery";

import Mobilemenu from "./Mobilemenu";
import StarRatings from 'react-star-ratings';

import PinDropIcon from '@material-ui/icons/PinDrop';
import CallIcon from '@material-ui/icons/Call';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

import logo from '../../assets/images/mw_logo.png';
import mb_logo from '../../assets/images/mw_logo.png';


// import img1 from '../../assets/img/cart/1.jpg';
// import img2 from '../../assets/img/cart/2.jpg';
// import img3 from '../../assets/img/cart/3.jpg';
// import img4 from '../../assets/img/cart/4.jpg';
// import logo from '../../assets/img/img/logo.png';
// Cart loop
// const cartposts = [
//     { img: img1, title: 'Oak Wood Cutting Board', price: '2x 10,000$' },
//     { img: img2, title: 'Oak Wood Cutting Board', price: '2x 10,000$' },
//     { img: img3, title: 'Oak Wood Cutting Board', price: '2x 10,000$' },
//     { img: img4, title: 'Oak Wood Cutting Board', price: '2x 10,000$' },
// ];
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      classmethod: false,
      togglemethod: false,
      togglecart: false,
    };
    this.addClass = this.addClass.bind(this);
    this.removeClass = this.removeClass.bind(this);
    this.toggleClass = this.toggleClass.bind(this);
    this.toggleCartm = this.toggleCartm.bind(this);
  }
  addClass() {
    this.setState({
      classmethod: true,
    });
  }

  removeClass() {
    this.setState({
      classmethod: false,
    });
  }
  toggleClass() {
    this.setState({
      togglemethod: !this.state.togglemethod,
    });
  }
  toggleCartm() {
    this.setState({
      togglecart: !this.state.togglecart,
    });
  }
  componentDidMount() {
    function megamenu() {
      $(".sigm-megamenu-nav>li").on("mouseover", function (e) {
        e.preventDefault();
        $(".sub-menu .sigm-megamenu-nav").find(".active").removeClass("active");
        $(".sub-menu .tab-content").find(".active").removeClass("active show");

        $(this).find("a").addClass("active");
        $(".sub-menu .tab-item").eq($(this).index()).addClass("active show");
      });
    }
    megamenu();
    window.addEventListener(
      "scroll",
      () => {
        this.setState({
          isTop: window.scrollY > 110,
        });
      },
      false
    );
  }

  topAddress(){
    return(
      <div className="top-address-view">
           {/* <div className="row header-hide">
           <div className="col-sm-3 col-lg-3 col-md-3 col-12 d-flex justify-content-center">
               <img src={logo} alt="no image"/>
                </div>
                <div className="col-sm-2 col-lg-2 col-md-2 col-12 d-flex justify-content-center">
               <div className="i-cover"><PinDropIcon className="icons h-i"/></div>BSNL Tower Land, Thamaraikarai, Anthiyur Taluk, Erode - 638501, Tamilnadu
                </div>
                <div className="col-sm-1 col-lg-1 col-md-1 d-flex justify-content-center">
                  <div className="row rating-section">
                <div className="col-12 rating">4.5 </div>
                  <div className="col-12 rating-text">Rating</div>
                  <div className="col-12">
                  <StarRatings
                    numberOfStar={5}
                    rating={4.5}
                    starDimension="10px"
                    starSpacing="5px"
                  />
                  </div>
                  </div>
                </div>
                <div className="col-sm-3 col-lg-3 col-md-3 col-12 d-flex justify-content-center">
                  
                <div className="i-cover"><CallIcon  className="icons h-i"/></div> For Booking & Enquiry :
                        <br/> 04256 292555 | 94455 26699 | 94869 76699
                    
                </div>
                <div className="col-sm-3 col-lg-3 col-md-3 col-12 d-flex justify-content-center">
                <div className="i-cover"><MailOutlineIcon className="icons h-i"/></div> moonwalkresortsindia@gmail.com 
                happyvalleyindia@gmail.com
                </div>
            </div> */}
      </div>
    )
  }


  aboutUs(){
    // document.body.scrollTop = 3000;
    window.scrollTo({
      top: 800,
      behavior: "smooth"
  });
  }
  home(){
    window.scrollTo({
      top:0,
      behavior: "smooth"
  });
  }
  room(){
    window.scrollTo({
      top:1300,
      behavior: "smooth"
  });
  }

  deluxRoom(){
    localStorage.setItem('activeRoom','deluxRoom');
  }

  suiteRoom(){
    localStorage.setItem('activeRoom','suiteRoom');
  }

  friendRoom(){
    localStorage.setItem('activeRoom','friendRoom');
  }

  render() {
    const stickyheader = this.state.isTop ? "sticky-active" : "";
    return (
      <Fragment>
        <header
          className={`header-three header-absolute sticky-header sigma-header ${stickyheader}`}
          id="header"
        >
         {this.topAddress()}
          
          <div className="main-menu-area sticky-header">
          {/* {this.topAddress()} */}
            <div className="container-custom-three">
              <div className="nav-container d-flex align-items-center justify-content-between">
                {/* Site Logo */}
                <div className="site-logo site-logo-text">
                              <div className="site-logo-inside"></div>
                              <Link to="/">
                                <img src={logo} alt=""/>
                              </Link>
                            </div>

                {/* Main Menu */}
                <div className="nav-menu d-lg-flex align-items-center justify-content-center w-100">

                  {/* Navbar Close Icon */}
                  <div className="navbar-close">
                    <div className="cross-wrap">
                      <span className="top" />
                      <span className="bottom" />
                    </div>
                  </div>

                  {/* Mneu Items */}
                  <div className="sigma-header-nav">
                    <div className="">
                      <div className="sigma-header-nav-inner">
                        <nav>
                          <ul className="sigma-main-menu">
                            <li className="menu-item menu-item-has-children">
                              <Link to="/" className="animated-border" onClick={()=>this.home()}>Home</Link>
                            </li>
                            <li className="menu-item menu-item-has-children">
                              <Link to="/" className="animated-border" onClick={()=>this.aboutUs()}>About</Link>
                            </li>
                            <li className="menu-item menu-item-has-children">
                              <Link to="/rooms" className="animated-border" onClick={()=>this.room()}>Room</Link>
                              <ul className="sub-menu">
                                                                <li className="menu-item">
                                                                    <Link onClick={()=>this.deluxRoom()} to="/rooms">Economy Deluxe Rooms</Link>
                                                                </li>
                                                               <li className="menu-item">
                                                                    <Link onClick={()=>this.friendRoom()} to="/rooms">Dormitory Friends Rooms</Link>
                                                                </li>
                                                                <li className="menu-item">
                                                                    <Link onClick={()=>this.suiteRoom()} to="/rooms">Premium Suite Rooms</Link>
                                                                </li>
                                                                
                                                            </ul>
                            </li>
                            <li className="menu-item menu-item-has-children">
                              <Link to="/restaurant" className="animated-border">Restaurant</Link>
                            </li>
                            <li className="menu-item menu-item-has-children">
                              <Link to="/bar" className="animated-border">Bar&nbsp;&&nbsp;Spa</Link>
                            </li>
                            
                            <li className="menu-item menu-item-has-children">
                              <Link to="/membership" className="animated-border">Membership</Link>
                            </li>
                            <li className="menu-item menu-item-has-children">
                              <Link to="/games" className="animated-border">Play&nbsp;Area</Link>
                            </li>
                            <li className="menu-item menu-item-has-children">
                              <Link to="/gallery" className="animated-border">Gallery</Link>
                            </li>
                            <li className="menu-item menu-item-has-children">
                              <Link to="/contact" className="animated-border">Contact</Link>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
                {/* navbar right content */}

                <div className="menu-right-buttons">
       
                  
                  {/* Navbar Toggler */}
                  <div className="navbar-toggler">
                    <span />
                    <span />
                    <span />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Mobile Header Start */}
          <div className="sigma-mobile-header">
            <div className="container">
              <div className="sigma-mobile-header-inner">
                {/* Site Logo */}
                <div className="site-logo site-logo-text">
                  <Link to="/">
                    
                    <div className="site-logo-text">
                 
                    <img src={logo} alt=""/>
                    </div>
                  </Link>
                </div>
                <div
                  className="sigma-hamburger-menu"
                  onClick={this.toggleClass}
                >
                  <div
                    className={classNames("sigma-menu-btn", {
                      active: this.state.togglemethod,
                    })}
                  >
                    <span />
                    <span />
                    <span />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Mobile Header End */}
          {/* Mobile Menu Start */}
          <aside
            className={classNames("sigma-mobile-menu", {
              active: this.state.togglemethod,
            })}
          >
            <Mobilemenu />
          </aside>
          {/* Mobile Menu End */}
        </header>
      </Fragment>
    );
  }
}

export default Header;
