import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import './style.css';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';

import logo from '../../assets/images/logo-lg.png';
import PinDropIcon from '@material-ui/icons/PinDrop';
import CallIcon from '@material-ui/icons/Call';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LanguageIcon from '@mui/icons-material/Language';

import bg from '../../assets/images/bg/t-bg.png';

function Footer() {
        return (
            <Fragment>
                {/* <Backtotop /> */}
                <footer className="footer-two" style={{ backgroundImage: "url(" + bg + ")",backgroundSize:"cover" }}>

                    <div className="copyright-area">
                        <div className="container">
                            <div className="row ">
                            <div className="col-lg-5 col-md-5 order-1 order-md-2">
                                    <div className="footer-nearby">
                                        {/* <div className="footer-nearby-img w-100 d-flex justify-content-center">
                                            <img src={logo} alt=""/>
                                        </div> */}
                                        <div className="footer-nearby-content p-3">
                                            <div className="d-flex justify-content-center w-100 footer-header-text"><h5><b>Nearby Attractions</b></h5></div>
                                            <div className="d-flex content-main"><div className="justify-content-start w-100">Thamaraikulam Pond </div><div className=" d-flex w-100 justify-content-end km">500 Meters</div></div>
                                            <div className="d-flex content-main"><div className="justify-content-start w-100">Eretty Water falls</div><div className=" d-flex w-100 justify-content-end km">6 Kms</div></div>
                                            <div className="d-flex content-main"><div className="justify-content-start w-100">Maniyachi Water falls</div><div className=" d-flex w-100 justify-content-end km">10 Kms</div></div>
                                            <div className="d-flex content-main"><div className="justify-content-start w-100">Varatu Pallam Dam</div><div className=" d-flex w-100 justify-content-end km">15 Kms</div></div>
                                            <div className="d-flex content-main"><div className="justify-content-start w-100">Karnataka Border</div><div className=" d-flex w-100 justify-content-end km">30 Kms</div></div>
                                            <div className="d-flex content-main"><div className="justify-content-start w-100">Sangameshwaran Temple</div><div className=" d-flex w-100 justify-content-end km">40 Kms</div></div>
                                            <div className="d-flex content-main"><div className="justify-content-start w-100">Malai Matheswaran Temple</div><div className=" d-flex w-100 justify-content-end km">50 Kms</div></div>
                                            <div className="d-flex content-main"><div className="justify-content-start w-100">Hugyam Dam</div><div className=" d-flex w-100 justify-content-end km">52 Kms</div></div>
                                            <div className="d-flex content-main"><div className="justify-content-start w-100">Mathala Kombu</div><div className=" d-flex w-100 justify-content-end km">55 Kms</div></div>
                                            <div className="d-flex content-main"><div className="justify-content-start w-100">Kodivery Dam</div><div className=" d-flex w-100 justify-content-end km">60 Kms</div></div>
                                            <div className="d-flex content-main"><div className="justify-content-start w-100">Mysore</div><div className=" d-flex w-100 justify-content-end km">145 Kms</div></div>
                                            <div className="d-flex content-main"><div className="justify-content-start w-100">Thalai Cauvery</div><div className=" d-flex w-100 justify-content-end km">300 Kms</div></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-7 col-md-7 order-2 order-md-2">
                                    <div className="row pt-5">
                                        <div className="col-sm-6 col-md-6 col-lg-6">
                                            <div className="footer-head-text "><h4>Our Policies</h4></div>
                                            <div className="footer-sub-text list-text">
                                            <ul>
                                                <li><Link to="/terms">Terms & Conditions</Link></li>
                                                <li><Link to="/reservation">Reservation & Cancellation Policy</Link></li>
                                                <li><Link to="/privacy">Privacy Policy</Link></li>
                                            </ul></div>

                                            <div className="footer-icon d-flex px-2">
                                                <div className="footer-icon px-1">
                                                <a href="https://www.facebook.com/MoonwalkResorts/" target="_blank"><FacebookIcon className="f-icons"/></a>
                                                </div>
                                                <div className="footer-icon px-1">
                                                <a href="https://www.instagram.com/moonwalkresorts/?utm_medium=copy_link" target="_blank"><InstagramIcon className="f-icons"/></a>
                                                </div>
                                                <div className="footer-icon px-1">
                                                <a href="https://www.youtube.com/MoonwalkResorts/" target="_blank"><YouTubeIcon className="f-icons"/></a>
                                                </div>
                                                <div className="footer-icon px-1">
                                                <a href="https://api.whatsapp.com/send?phone=+919445526699" target="_blank"><WhatsAppIcon className="f-icons"/></a>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-sm-6 col-md-6 col-lg-6">
                                        <div className="footer-head-text"><h4>Connect With Us</h4></div>
                                            <div className="footer-sub-text">
                                            <ul>
                                                <li className="d-flex mb-2"><div className="i-covers px-2 "><PinDropIcon className="icons h-i"/></div>BSNL Tower Land, Thamaraikarai Hillstation, Bargur Road, Anthiyur Taluk,Erode District - 638501, Tamilnadu</li>
                                                <li className="mb-2"><div className="d-flex"><div className="i-covers px-2 "><CallIcon  className="icons h-i"/></div> For Booking & Enquiry :
                        </div> <div className="sub-number"> &nbsp;<a className="mt-2" href="tel:+9194455 26699"> 94455 26699</a>&nbsp; | &nbsp;<a className="mt-2" href="tel:+9194455 26699"> 94869 76699</a></div></li>
                        {/* <a className="ml-4 mr-2 mt-2" href="tel:+9104256 292555">04256 292555 </a>&nbsp; | */}
                                                <li className="d-flex mb-2"><div className="i-covers px-2 mt-2">
                                                    <MailOutlineIcon className="icons h-i mt-2"/></div> 
                                                    <ul><li><div><a className="mail-service mt-2" href="mailto:moonwalkresortsindia@gmail.com"
                                                    >moonwalkresortsindia@gmail.com</a></div></li>
                                                    <li><div><a className="mail-service mt-2" href="mailto:happyvalleyindia@gmail.com"
                                                    >happyvalleyindia@gmail.com</a></div></li>                                                 
                                                    </ul></li>
                                                    <li className="d-flex mb-2"><div className="i-covers px-2 mt-2"><LanguageIcon className="icons h-i"/></div><a className="mail-service mt-2" target="_blank" href="www.moonwalkresorts.in"
                                                    >www.moonwalkresorts.in</a></li>
                                            </ul></div>
                                        </div>
                                    </div>
                                    <div className="d-flex row w-100 mt-5 justify-content-center">
                                    <div className="col-sm-12 col-lg-12 col-md-12"><p className="copyright-text copyright-two"><Link to="#"> © All copyright reserved by Moonwalk Resorts - 2021  </Link></p></div>
                                    <div className="row d-flex w-100 justify-content-end">
                                        <div className="col-12 d-flex justify-content-center dev-text pt-2">Designed & Developed By Outwitz Technologies</div>
                            </div>
                                    <div className="col-sm-8 col-lg-8 col-md-8"><div className="footer-menu text-center text-md-right">
                                        {/* <ul className="d-flex">
                                            <li><Link to="#">Terms of use</Link></li>
                                            <li><Link to="#">Privacy Environmental Policy</Link></li>
                                        </ul> */}
                                    </div>
                                    </div>
                                    </div>
                                </div>
                                
                            </div>
                            
                        </div>
                        
                    </div>
                </footer>

            </Fragment>
        );
    }
export default Footer;