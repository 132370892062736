import React, { useEffect } from "react";

import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import StarRateIcon from '@mui/icons-material/StarRate';
import border from '../../../assets/images/under/i3.png';
import "./style.css"

export default function Privacy() {
    useEffect(() => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }, [])

    return (
        <div>
            <Header />
            <section className="mt-5">
                <div className="container">
                    <div className="d-flex justify-content-center about-title terms-title mt-5">

                        <h2 className="mt-5 pt-3">PRIVACY POLICY</h2>
                    </div>
                    <div className="d-flex justify-content-center header-img"><img src={border} /></div>
                    <div className="contact-card">
                        <div className="row mt-3 mb-5">

                            <div className="col-12">
                                <div className="p-content">
                                    <div class="justify-content-start room-title">
                                        <div>GENERAL</div>
                                        <div class="bottom-border"></div></div>
                                    <div class="sub-text-room">
                                        <StarRateIcon className="star-icon"/>This policy covers how we treat your information (including personal information) that we collect and receive, including information related to your participation in programs conducted by us, or visit or use (including past visits or use) of our websites, emails, other products and/or services (“Site”).
                                    </div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>You should read this policy before using the Site. By using or accessing the Site, or any part of it, you agree and acknowledge that you have read and understood the policy and consent to the collection, use and disclosure of your information as described in this policy. You should not use or access the Site, or any part of it, if you do not agree with any portion of this policy.
                                    </div>
                                    <div>
                                    </div>
                                </div>

                                <div className="p-content">
                                    <div class="justify-content-start room-title">
                                        <div>INFORMATION THAT WE COLLECT </div>
                                        <div class="bottom-border"></div></div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>When you use the Site or register with us, you will provide us with information including, without limitation, your demographic information, your transaction information and your personal information. Further, during your access and use of the Site, we may learn and collect additional information from and about you.
                                    </div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>Personal information is information about you that is personally identifiable like your name, address, email address, phone number, gender, age, billing or credit card information and that is not otherwise publicly available. The term information shall include personal information.
                                    </div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>When you use the Site, we may also collect and store additional information which may or may not be personally identifying to you or identifiable with other information collected. Such information includes, without limitation, your internet protocol address, browser types, domain name, type of operating system and other statistical data involving your use of the Site and/or our services. This information is collected passively using various technologies or may be provided by you through communications with us.
                                    </div>
                                    <div>
                                    </div>
                                </div>

                                <div className="p-content">
                                    <div class="justify-content-start room-title">
                                        <div>COOKIES</div>
                                        <div class="bottom-border"></div></div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>When you use the Site, we may store some information on your computer or other device used to access the Site. This information will be in the form of a “cookie” or similar file. Cookies are small pieces of information that gets stored on your computer or device when you access the Site. We use cookies to track your usage, trends on the usage, and to improve the Site and analyse and assess changes to, successes and failures of the Site. These cookies remember information about your usage of the Site and help you navigate the Site. We do not use cookies to spy on you or otherwise invade your privacy.
                                    </div>
                                    <div>
                                    </div>
                                </div>

                                <div className="p-content">
                                    <div class="justify-content-start room-title">
                                        <div>OTHER MONITORING PROGRAMS</div>
                                        <div class="bottom-border"></div></div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>For security purposes and to ensure the availability of the Site, we also use software programs and tools to monitor network traffic, to identify unauthorised attempts to upload or change information, or otherwise cause damage to the Site.
                                    </div>
                                    <div>
                                    </div>
                                </div>

                                <div className="p-content">
                                    <div class="justify-content-start room-title">
                                        <div>CUSTOMER SERVICE</div>
                                        <div class="bottom-border"></div></div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>We may also collect information when you interact with our customer service in order to assist you and to improve the operation of the Site.
                                    </div>
                                    <div>
                                    </div>
                                </div>

                                <div className="p-content">
                                    <div class="justify-content-start room-title">
                                        <div>HOW WE USE YOUR INFORMATION</div>
                                        <div class="bottom-border"></div></div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>The information that we collect is used to contact you, to administer your accounts, for research, and to improve the quality of our service [and develop our present and future services and provide information of these services to you]. We will not sell, share or disclose your information with any third party without your consent, except as provided for in this policy. We may disclose your information to service providers who you may be require such information for the provisions of services selected by you.
                                    </div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>We reserve the right to send you certain communications relating to the Site, such as service announcements, administrative messages and information regarding existing and new services, without offering you the opportunity to opt out of receiving them.
                                    </div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>You may also provide us with access to information which is already publicly available, or becomes publicly available to us in the future. Further, we may also access information about you from third parties independent of your use of the Site. You understand and acknowledge that any such information we may collect cannot be accorded the protections of this policy.
                                    </div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>This policy does not apply to the practices of other entities that we do not own or control, or to people that we do not employ or manage.
                                    </div>
                                    <div>
                                    </div>
                                </div>
                                
                                <div className="p-content">
                                    <div class="justify-content-start room-title">
                                        <div>STORAGE AND DESTRUCTION</div>
                                        <div class="bottom-border"></div></div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>We shall store all information in accordance with standard market practices relating to storage of information and this may involve storage in servers and databases owned and maintained by us and/or our affiliates, agents or service providers. We may also retain the information once the purpose for which it is collected has been completed and the same may be retained and/or destroyed in accordance with standard market practices applicable to similar businesses.
                                    </div>
                                    <div>
                                    </div>
                                </div>

                                <div className="p-content">
                                    <div class="justify-content-start room-title">
                                        <div>ACCESS AND PROTECTION OF YOUR INFORMATION</div>
                                        <div class="bottom-border"></div></div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>Your information that is provided to us or collected by us shall be protected in accordance with standard market practices applicable to similar businesses.
                                    </div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>You have a right to access, modify or correct any information provided to us by the use of the Site. If you update, modify or correct your information, we may continue to keep copies of your information prior to such update, modification or correction for uses provided for in this policy. You can request internal deletion of your prior information at any time by requesting us with specific details of the information that you wish to delete, and we shall endeavor to respond to your request in a timely manner.
                                    </div>
                                    <div>
                                    </div>
                                </div>

                                <div className="p-content">
                                    <div class="justify-content-start room-title">
                                        <div>DISCLOSURE OF INFORMATION</div>
                                        <div class="bottom-border"></div></div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>We shall not rent, sell, share or disclose your information with non-affiliated companies or persons, except as provided in this policy or the applicable terms of use of the Site, or to provide you with the requested services on the Site, or when we have your consent, or under the following circumstances:
                                    </div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>We provide the information to our trusted employees who work on our behalf or with us under confidentiality agreements. These employees may use your information to help us communicate with you about our Site and our other services. [However, these employees do not have any independent right to share this information];
                                    </div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>We provide the information to our advisors and consultants for billing, business, legal and taxation purposes;
                                    </div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>We may disclose the information pursuant to law, court orders, requests or orders of Governmental authorities, or to exercise our legal rights or defend against legal claims;
We believe it is necessary to share information in order to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the safety of any person, violations of the applicable terms of use of the Site, or as otherwise required by law;
We believe it is necessary to disclose the information to enforce the applicable terms of use of the Site and/or reasonably necessary to protect our rights and property or our partners, employees or agents;
                                    </div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>We share your information with third parties who we may be in discussions with for investments in or financing of our projects;
                                    </div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>We transfer information about you if we are acquired by or merged with another company or transfer a part of our business to a third party. Any third party or resultant entity that receives your information pursuant to a merger, demerger or business transfer shall have the right to continue to use your information. In this event, we will notify you before information about you is transferred and becomes subject to a different privacy policy.
                                    </div>
                                    <div class="sub-text-room">
                                    <StarRateIcon className="star-icon"/>We may publish any testimonials that you have provided about the Site and such testimonials and other marketing, promotions and advertisements may include your information that you have provided to us.
                                    </div>
                                    <div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}
