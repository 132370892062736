import InstagramEmbed from 'react-instagram-embed';
import React, { Component } from 'react';
import Carousel from 'react-elastic-carousel'
import "./style.css";

import border from '../../../assets/images/under/i3.png';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';

import { Fade } from "react-awesome-reveal";
import Slider from "react-slick";
// import video from "../../../assets/video/video.mp4";

import fi from '../../../assets/images/original/f-icon/rest.png';
import fi1 from '../../../assets/images/original/f-icon/con_h.png';
import fi2 from '../../../assets/images/original/f-icon/games.png';
import fi3 from '../../../assets/images/original/f-icon/swming.jpg';
import fi4 from '../../../assets/images/original/f-icon/bar.png';
import fi5 from '../../../assets/images/original/f-icon/fish.png';
import fi6 from '../../../assets/images/original/f-icon/open.png'
import fi7 from '../../../assets/images/original/f-icon/terk.png'
import fi8 from '../../../assets/images/original/f-icon/wifi.jpg'
import fi9 from '../../../assets/images/original/f-icon/bon.jpg'
import fi10 from '../../../assets/images/original/f-icon/ride.png'
import fi11 from '../../../assets/images/original/f-icon/parking.jpg'
import fi12 from '../../../assets/images/original/f-icon/safari.png'
import fi13 from '../../../assets/images/original/f-icon/spa.png'


import img from '../../../assets/images/facility/f1.jpg';
import img6 from '../../../assets/images/facility/f2.jpg';
import img1 from '../../../assets/images/facility/f3.jpg';
import img2 from '../../../assets/images/facility/f4.jpg';
import img3 from '../../../assets/images/facility/f5.jpg';
import img4 from '../../../assets/images/facility/f6.jpg';
import img5 from '../../../assets/images/facility/f7.jpg';
import img7 from '../../../assets/images/facility/f8.jpg';
import img8 from '../../../assets/images/facility/f9.jpg';
import img9 from '../../../assets/images/facility/f10.jpg';
import img10 from '../../../assets/images/facility/f11.jpg';
import img11 from '../../../assets/images/facility/f12.jpg';
import img12 from '../../../assets/images/facility/f13.jpg';
import img13 from '../../../assets/images/facility/f14.jpg';
import img14 from '../../../assets/images/facility/f15.jpg';
import img15 from '../../../assets/images/facility/bon.jpeg';
import img16 from '../../../assets/images/facility/f2.jpg';
import RoomCarosual from '../rooms/carosual';

import ArrowCircleDownSharpIcon from '@mui/icons-material/ArrowCircleDownSharp';

const facilityDetail = [
    { img: img, icon: '', title: 'Bar', right: 'Admin', btn_link: '', direction:"right", delay:300 },
    { img: img1, icon: '', title: 'Games', right: 'Admin', btn_link: '', direction:"left", delay:300 },
    { img: img2, icon: '', title: 'Open Theater', right: 'Admin', btn_link: '', direction:"right", delay:300 },
    { img: img3, icon: '', title: 'Trekking', right: 'Admin', btn_link: '', direction:"left", delay:200 },
    { img: img4, icon: '', title: 'Restaurant', right: 'Admin', btn_link: '', direction:"right", delay:200 },
    { img: img5, icon: '', title: 'River Dip', right: 'Admin', btn_link: '', direction: "left", delay: 200 },
    { img: img7, icon: '', title: 'FREE Wi - Fi', right: 'Admin', btn_link: '', direction: "left", delay: 200 },
    { img: img8, icon: '', title: 'Conference Hall', right: 'Admin', btn_link: '', direction: "left", delay: 200 },
    { img: img9, icon: '', title: 'Parking Area', right: 'Admin', btn_link: '', direction: "left", delay: 200 },
    { img: img10, icon: '', title: 'Camp Fire', right: 'Admin', btn_link: '', direction: "left", delay: 200 },
    { img: img6, icon: '', title: 'Swimming Pool', right: 'Admin', btn_link: '', direction:"left", delay:200 },
    { img: img11, icon: '', right: 'Admin', btn_link: '', direction:"left", delay:200 },
    { img: img12, icon: '',  right: 'Admin', btn_link: '', direction:"left", delay:200 },
    { img: img13, icon: '',  right: 'Admin', btn_link: '', direction:"left", delay:200 },
    { img: img14, icon: '',  right: 'Admin', btn_link: '', direction:"left", delay:200 },
    { img: img15, icon: '',  right: 'Admin', btn_link: '', direction:"left", delay:200 },
    { img: img16, icon: '',  right: 'Admin', btn_link: '', direction:"left", delay:200 },
];

const right=[{ img: fi8, icon: '', title: 'FREE Wi - Fi', class: 'fi1', btn_link: '', direction: "left", delay: 200 },
{ img: fi1, icon: '', title: 'Conference Hall', class: 'fi2', btn_link: '', direction: "left", delay: 200 },
{ img: fi11, icon: '', title: 'Parking Area', class: 'fi3', btn_link: '', direction: "left", delay: 200 },
{ img: fi9, icon: '', title: 'Bon Fire', class: 'fi4', btn_link: '', direction: "left", delay: 200 },
{ img: fi3, icon: '', title: 'Swimming Pool', class: 'fi5', btn_link: '', direction:"left", delay:200 },
{ img: fi12, icon: '', title: 'Safari Ride', class: 'fi6', btn_link: '', direction: "left", delay: 200 },
{ img: fi5, icon: '', title: 'Fishing at Thamaraikulam Pond', class: 'fi6', btn_link: '', direction: "left", delay: 200 },];


const left=[{ img: fi4, icon: '', title: 'Open Bar & Club', class: 'fi1', btn_link: '', direction:"right", delay:300 },
{ img: fi2, icon: '', title: 'Games', class: 'fi2', btn_link: '', direction:"left", delay:300 },
{ img: fi6, icon: '', title: 'Open Theater', class: 'fi3', btn_link: '', direction:"right", delay:300 },
{ img: fi7, icon: '', title: 'Trekking', class: 'fi4', btn_link: '', direction:"left", delay:200 },
{ img: fi, icon: '', title: 'Restaurant', class: 'fi5', btn_link: '', direction:"right", delay:200 },
{ img: fi13, icon: '', title: 'Mountain Spa', class: 'fi6', btn_link: '', direction: "left", delay: 200 },
{ img: fi10, icon: '', title: 'ATV Bike Ride', class: 'fi6', btn_link: '', direction: "left", delay: 200 },
];

export default class Facility extends Component {
    constructor(props) {
        super(props);
    }
    

    // componentDidMount() {
    //     window.InstagramEmbed.process();
    // }

    render() {
        
        
        return (
            <div>
            <div className="section bg-room-section" >
                <div className="justify-content-center w-100 frent-content">
                    <div className="header-text w-header">
                        <div>Facilites Offered</div>
                    </div>
                    <div className="d-flex justify-content-center header-img"><img src={border} /></div>
                </div>
                
                <div className="mb-5 pb-5 p-4 frent-content">

                <div className="row">
                    <div className="col-sm-3 col-md-12 col-lg-3 px-0 pt-4">
                        <div className="facility-card"><div className="row px-0 pt-3 pb-4">
                    {left.map((item, i) => (
                      <div className="col-12 pt-2 px-4"> {item.title?<div className="container facility-name">
                             <img src={item.img}className={item.class}/>{item.title}</div>:''}</div>
                    ))}</div>
                    </div></div>
                    <div className="col-sm-6 col-md-12 col-lg-6 p-0">
                    <RoomCarosual data={facilityDetail} silde={1} name={'facility'} facility={'facility'}/>
                    </div>
                    <div className="col-sm-3 col-md-12 col-lg-3 px-0 pt-4">
                        <div className="facility-card"><div className="row px-0 pt-3 pb-4">
                    {right.map((item, i) => (
                      <div className="col-12 pt-2 px-4"> {item.title?<div className="container facility-name ">
                           <img src={item.img}className={item.class}/>{item.title}</div>:''}</div>
                    ))}
                  
                    </div>
                    </div></div>
                </div>
                <div className="justify-content-center w-100 frent-content nearby">
                    <div className="header-text w-header">
                        <div>Our Aerial View</div>
                    </div>
                    <div className="d-flex justify-content-center header-img"><img src={border} /></div>
                </div>
                <div className="outvideo">
                <video
                    loop
                    autoPlay
                    muted={true}
                    playsInline
                    className="largevideo justifycontent-center d-flex w-100"
                    preload="none"
                    // src={video}
                    type="video/mp4"
                    />
                </div>
         
                </div>
               

                </div>
                </div>
        );
    }
}

