import { Dropdown } from "bootstrap";
import React, { useEffect } from "react";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import bg from '../../../assets/images/bg/bg_d_1.jpg';
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import border from '../../../assets/images/under/i3.png';
import CheckIcon from '@material-ui/icons/Check';
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';
import Lottie from "../common/LottieAnimation";
import Whatsapp from "../common/whatsapp-icon.json";
import { Fade } from "react-awesome-reveal";
import { Tab, Nav } from "react-bootstrap";
import "./style.css"

export default function Membership() {
  const handleChange = (event) => { };
  useEffect(() => {
    document.body.scrollTop =0;
    document.documentElement.scrollTop = 0;
},[])

  return (
    <section>
      <Header />
      <div className="section pt-5 mt-5 pb-5">
      <div className="container">
          <div className="membership mt-5">
            <div className="justify-content-center w-100 pb-3">
              <div className="header-text">
                <div>Membership Offer</div>
              </div>
            <div className="d-flex justify-content-center header-img"><img src={border} /></div>
            <div className="d-flex justify-content-center w-100 header-sub-text">
              <FormatQuoteIcon/><h4>Get a happiest family vacation satarting from 1 lakh for 9 years with luxury stay</h4><FormatQuoteIcon/>
              </div>
            </div>
            

              <div className="row">
              <div className="col-sm-6">
              <Fade delay={700} direction={"left"}>
                <div className="member-card">
                <div className="d-flex justify-content-center w-100 member-header">
                  <h3>Golden Membership</h3>
                </div>
                <div className="member-list">
                  <ul>
                    <li className="d-flex"><CheckIcon className="check-icon"/><h5>Valid 9 Years from the date of Subscription</h5></li>
                    <li className="d-flex"><CheckIcon className="check-icon"/><h5>Deluxe Room Staying Free for 4 nights / 5 days in a year</h5></li>
                    <li className="d-flex"><CheckIcon className="check-icon"/><h5>15% Discount on Restaurant for 9 years</h5></li>
                    <li className="d-flex"><CheckIcon className="check-icon"/><h5>25% Discount on Spa for 9 years</h5></li>
                    <li className="d-flex"><CheckIcon className="check-icon"/><h5>15% Discount on Bar for 9 years</h5></li>
                  </ul>
                </div>
                </div>
                </Fade>
              </div>
              
              <div className="col-sm-6">
              <Fade delay={700} direction={"right"}>
              <div className="member-card">
                <div className="d-flex justify-content-center w-100 member-header">
                  <h3>Platinum Membership</h3>
                </div>
                <div className="member-list">
                  <ul>
                    <li className="d-flex"><CheckIcon className="check-icon"/><h5>Valid 9 Years from the date of Subscription</h5></li>
                    <li className="d-flex"><CheckIcon className="check-icon"/><h5>Premium Suite Staying Free for 4 nights / 5 days in a year</h5></li>
                    <li className="d-flex"><CheckIcon className="check-icon"/><h5>15% Discount on Restaurant for 9 years</h5></li>
                    <li className="d-flex"><CheckIcon className="check-icon"/><h5>25% Discount on Spa for 9 years</h5></li>
                    <li className="d-flex"><CheckIcon className="check-icon"/><h5>15% Discount on Bar for 9 years</h5></li>
                  </ul>
                </div>
                </div>
                </Fade>
              </div>
              
              </div>
              
        
            <div className="justify-content-center w-100 pb-3 mt-5">
              <div className="header-text">
                <b>Join Membership</b>
              </div>
            <div className="d-flex justify-content-center header-img"><img src={border} /></div>
            <div className="join-membership">
            <div className="d-flex justify-content-center w-100 header-sub-text p-5">
            <h4>Make a Privilege Membership card of our Moonwalk Resort by which you are entitled for Special Discounts and offers
                on Room, Restaurant, Bar & Spa. Please fill up the below details to the enquiry.</h4>
            </div>
                <div className="whats p-5 d-flex justify-content-center w-100">
                  <div className="whatsapp-button d-flex"><a href="https://api.whatsapp.com/send?phone=+919445526699">
                    <Lottie lotti={Whatsapp} loop={true} height={80} width={80} />
                    <h4>Join MemberShip</h4></a>
            </div>
            </div>
            </div>
            </div>
          </div>
          </div>
      </div>
      <Footer />
    </section>
  );
}
