import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ContactUs from "../app/component/content/contactUs";
import Gallery from "../app/component/content/gallery";
import Games from "../app/component/content/games";
import Membership from "../app/component/content/memberShip";
import Restaurant from "../app/component/content/restaurant";
import BarSpa from "../app/component/content/bar&spa";
import RoomsDetail from "../app/component/content/rooms";
import DeluxRoom from "../app/component/content/rooms/deluxRoom";
import Home from "../app/component/pages/Home";
import Privacy from "../app/component/content/privacy/Privacy";
import Terms from "../app/component/content/privacy/Terms";
import Reservation from "../app/component/content/privacy/Reservation";

function Navigation() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/membership" component={Membership} />
        <Route path="/games" component={Games} />
        <Route path="/rooms" component={RoomsDetail} />
        <Route path="/drooms" component={DeluxRoom} />
        <Route path="/gallery" component={Gallery} />
        <Route path="/contact" component={ContactUs} />
        <Route path="/restaurant" component={Restaurant} />
        <Route path="/bar" component={BarSpa} />
        <Route path="/privacy" component={Privacy} />
        <Route path="/terms" component={Terms} />
        <Route path="/reservation" component={Reservation} />
      </Switch>
    </BrowserRouter>
  );
}

export default Navigation;
