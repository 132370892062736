import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import img1 from '../../assets/images/png/up-1.png';

import botImg from '../../assets/images/gif/chatbot.gif';
import botCImg from '../../assets/images/gif/close.png';

import joker from '../../assets/images/ask-jokker.png';

import ReactChatBot from './ChatBot';

class Backtotop extends Component {
    constructor(props) {
        super(props);
        this.state = {hide:false};
    }
    // Back to top
    componentDidMount() {
        window.addEventListener('scroll', () => {
            this.setState({
                isTop: window.scrollY > 300
            });
        }, false);
    }
    scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
    bot = () => {
        this.setState({hide:!this.state.hide})
    }

    render() {
        const backtotop = this.state.isTop ? 'active' : '';
        return (
            <div>
                <div className="chat-bot-top">
                <img className="joker" src={this.state.hide?'':joker}/>
                </div>
                <div className="chat-bot-cart">
                    
                    <button onClick={() => this.bot()} className={this.state.hide?'close-btn-i':''}> {<img src={this.state.hide?botCImg:botImg}/>} </button>
                </div>
                
                {this.state.hide?<ReactChatBot />:''}
            <Link to="#" className={`back-to-home back-to-top ${backtotop}`} id="backToTop" onClick={() => this.scrollToTop()}>
                <img className="footer-top-img" src={img1} alt="image not found"/>
            </Link>
            </div>
        );
    }
}

export default Backtotop;