import React, { Component } from "react";
import Slider from "react-slick";
import "./style.css";

import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

export default class RoomCarosual extends Component {

  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
  changeImg(img) {
    localStorage.setItem("room-img",img)  
  }

  render() {
    const settings = {
      dots: this.props.facility==='facility'?false:true,
      arrows: this.props.facility!=='facility'?false:true,
      infinite: true,
      fade:this.props.facility==='facility'?true:false,
      speed: 500,
      slidesToShow: this.props.silde?this.props.silde:5,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
            arrows: false,
          }
        },
        {
          breakpoint: 600,
          settings: {
            arrows: false,
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 480,
          settings: {
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    return (
      <div className="container-fluid mt-3 p-0">
        {this.props.name!=="contact" && this.props.facility!=='facility'?<div style={{ textAlign: "right" }}>
          <button className="c-btn" onClick={this.previous}>
            <KeyboardBackspaceIcon />
          </button>
          <button className="c-btn" onClick={this.next}>
            <ArrowRightAltIcon />
          </button>
        </div>:''}
        <div className="col-lg-12 col-sm-12 col-12 friend-carosual p-0">
          <Slider ref={c => (this.slider = c)}  {...settings} className="f-carosual-silder">
            {this.props.data.map((item, i) => (
                  <div key={i} className="col-lg-12 p-0">
                    <div className="f-carosual-box">
                      {this.props.facility!=='facility'?<>
                      <div className="f-carosual-img" onMouseMove={()=>this.changeImg(item.img)}>
                        <img src={item.img} alt="not found" />
                      </div></>:<><div className="p-3">
                      <div className="image-carosual">
                                    <div className="image-card p-1">
                                        <img src={item.img} />
                                    </div>
                                    {/* <div className="image-content d-flex justify-content-center">
                                        {item.title}
                                    </div> */}
                                </div>
                                </div></>}
                    </div>
                  </div>
                ))}
          </Slider>
         
        </div>

      </div>
    );
  }
}