import { Dropdown } from "bootstrap";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import bg from '../../../assets/images/bg/bg_d_1.jpg';
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import border from '../../../assets/images/under/i3.png';
import img from '../../../assets/images/original/games/c.jpg';
import img1 from '../../../assets/images/original/games/g.JPG';
import img2 from '../../../assets/images/original/games/62.JPG';
import img3 from '../../../assets/images/original/games/48.JPG';
import img4 from '../../../assets/images/original/games/safari.jpg';

import img5 from '../../../assets/images/original/games/44.JPG';
import img6 from '../../../assets/images/original/games/43.JPG';
import img7 from '../../../assets/images/original/games/c-6.jpg';
import img8 from '../../../assets/images/original/games/g3.jpg';
import img9 from '../../../assets/images/original/games/37.JPG';

import { Tab, Nav } from "react-bootstrap";
import { Fade } from "react-awesome-reveal";
import { useState, useEffect } from "react";
import './style.css';

export default function Games() {
  const [outdoor, setOutdoor] = useState([
    { id: 1, img: img, title: "Chess", delay: 700 },
    { id: 2, img: img1, title: "Carrom", delay: 800 },
    { id: 3, img: img2, title: "Play station", delay: 900 },
    { id: 4, img: img3, title: "Indoor Shuttle Court", delay: 1000 },
    { id: 5, img: img4, title: "Safari ride", delay: 1100 },
    // { id: 6, img: img5, title:"Musical chairs", delay: 1200},
  ])

  const [indoor, setIndoor] = useState([
    { id: 1, img: img5, title: "Volley Ball", delay: 700 },
    { id: 2, img: img6, title: "Cricket", delay: 800 },
    { id: 3, img: img8, title: "ATV Bike rides", delay: 900 },
    { id: 4, img: img7, title: "Kids Play Area", delay: 1000 },
    { id: 4, img: img9, title: "Kids Play Area", delay: 1000 },
    // { id: 5, img: img4, title: "Musical chairs", delay: 1100 },
    // { id: 6, img: img5, title:"Musical chairs", delay: 1200},
  ])

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, [])

  const handleChange = (event) => { };

  return (
    <section>
      <Header />
      <div className="games-section">
        {/* style={{ backgroundImage: "url(" + bg + ")" }} */}
        <div className="p-3">
          <div className="booking-room">
            <div className="justify-content-center w-100 game-border">
              <div className="header-text">
                <div>Games</div>
              </div>
              <div className="d-flex justify-content-center header-img"><img src={border} /></div>
            </div>
            <div className="d-flex justify-content-center note-card"><p className="p-2 sub-text">
              <b> Note : </b> Please Bring Shuttle Racket and Cork for Indoor. No Rackets and Cork Will be provided.
            </p>
            </div>
            <div className="d-flex justify-content-center note-card"> <p className="p-2 sub-text">Games are sometimes played purely for enjoyment, sometimes for achievement or reward as well.
              They can be played alone, in teams, or online; by amateurs or by professionals.
              The players may have an audience of non-players, such as when people are entertained by watching a
              chess championship. On the other hand, players in a game may constitute their own audience as they
              take their turn to play. Often, part of the entertainment for children playing a game is deciding
              who is part of their audience and who is a player. A toy and a game are not the same.
              Toys generally allow for unrestricted play whereas games come with present rules.</p></div>

            <div className="row mt-4">
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <div className="row">
                  <div className="col-sm-3">
                    <Nav variant="pills" className="flex-column">
                      <Nav.Item>
                        <Nav.Link eventKey="first">Indoor Games</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">Outdoor Games</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                  <div className="col-sm-9">
                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        <div className="d-flex justify-content-center p-3 note-card"><p className="p-3 sub-text">
                          For Indoor games such as PS4 and XBOX consoles it's not free of cost.
                          You will be charged Rs.250/- per hour
                        </p>
                        </div>

                        <div className="row">{outdoor.map((item) => (
                          <div className="col-sm-4 pb-5">
                            <Fade delay={item.delay} direction={"down"}>
                              <div className="games-cards">
                                <div className="games-img"><img src={item.img} /></div>
                                <div className="games-cards-text">{item.title}</div>
                              </div>
                            </Fade>
                          </div>))}
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                      <div className="d-flex justify-content-center p-3 note-card"><p className="p-3 sub-text">
                      ATV BIKE RIDES are available, It will be charged Rs.500/- for 15 mins ride
                        </p>
                        </div>
                        <div className="row">{indoor.map((item) => (
                          <div className="col-sm-4 pb-5">
                            <Fade delay={item.delay} direction={"down"}>
                              <div className="games-cards">
                                <div className="games-img"><img src={item.img} /></div>
                                <div className="games-cards-text">{item.title}</div>
                              </div>
                            </Fade>
                          </div>))}
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </div>
              </Tab.Container>
            </div>

          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
}
