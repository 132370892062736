import React, { Component } from 'react';
import Slider from "react-slick";

import img from '../../../assets/images/feedback/f-1.png';
import img1 from '../../../assets/images/feedback/f-2.png';
import img2 from '../../../assets/images/feedback/f-3.png';
import img3 from '../../../assets/images/feedback/f-4.png';
import img4 from '../../../assets/images/feedback/f-5.png';
import img5 from '../../../assets/images/feedback/f-6.png';
import img6 from '../../../assets/images/feedback/f-7.png';       

import feedback from '../../../assets/images/original/main-img/11.jpg';

import StarRatings from 'react-star-ratings';

import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import Reveal, { Fade } from "react-awesome-reveal";

import { keyframes } from '@emotion/react';
const customAnimation = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 200px, 0);
  }
to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

function AnimatedComponent({ children }) {
  return <Reveal keyframes={customAnimation} delay>{children}</Reveal>;
}

const bannerDetail = [
    { img: img, name: 'Saba Reesh', feedback: 'I really had a splendid moment in the resort. My friends and myself enjoyed ourselves to the core. Food tastes real good.. Also there are lots of indoor and outdoor games which boosts our energy. Totally it was an amazing and awesome experience to all of us.. 🥰🤩', rating: 5, btn_link: '' },
    { img: img1, name: 'Manoj', feedback: 'Rooms  and amenities is excellent. Food was not ready on time . Otherwise I recommend people for the stay. Pool, games options are very good.', rating: 4, btn_link: '' },
    { img: img2, name: 'Flyerzz Baranee', feedback: 'Nice location to enjoy your leisure time into a memorable day...Best vacation spot in burger...', rating: 5, btn_link: '' },
    { img: img3, name: 'LOGESH KUMAR', feedback: 'Good response, On time delivery, Fair price', rating: 5, btn_link: '' },
    { img: img4, name: 'umasri ml', feedback: 'Stay was pleasent and location was really awesome. Amenities were excellent and we all entertained. Rooms were clean and well maintained and pool was very clean. Foods were tasty and we all served.', rating: 5, btn_link: '' },
    { img: img5, name: 'Gokul Boopathy', feedback: 'Stress relief place and the super one to relax with friends...', rating: 5, btn_link: '' },
    { img: img6, name: 'Bhargavi Karunakaran', feedback: 'The food tastes real good.. it"s just yummmmmmm🥳😋', rating: 5, btn_link: '' },
];
export default class  FeedBack extends Component {
    constructor(props) {
      super(props);
      this.next = this.next.bind(this);
      this.previous = this.previous.bind(this);
    }
    next() {
      this.slider.slickNext();
    }
    previous() {
      this.slider.slickPrev();
    }
    render() {
        const settings = {
            slidesToShow: 1,
            slidesToScroll: 1,
            fade: true,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 3000,
            arrows:false,
            dots: false,
            responsive: [{
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                },
            },
            ],
        }
        return (
          <section className="container feedback-main">
            <AnimatedComponent>
            <div className="feedback-section-card">
            <div className="row ">
                  <div className="col-sm-6 col-lg-6 col-md-6">
                    <img src={feedback} className="p-2" />
                  </div>
              <div className="col-sm-6 col-lg-6 col-md-6 p-5">
                <div className="f-heading d-flex justify-content-center">
                    <h2><b>Customer Review</b></h2>
                  </div>
                    <Slider className="f-latest-post-slider mt-3" ref={c => (this.slider = c)} {...settings}>
                        {bannerDetail.map((item, i) => (
                            <div key={i} className="col-lg-12">
                            <div className="f-latest-post-box">
                                <div className="d-flex">
                                <div className=" pt-2 f-post-img">
                                  <div className="f-thumb">
                                    <img src={item.img} />
                                    </div> <h5><b>{item.name}</b></h5>
                                  
                                  </div>
                              </div>
                              <div className="f-rating">
                              <StarRatings
                                  numberOfStar={5}
                                  starRatedColor="red"
                                  starEmptyColor="black"
                    rating={item.rating}
                    starDimension="15px"
                    starSpacing="5px"
                  />
                              </div>
                                <div className="f-silder-content justify-content-center w-100 pt-4">
                                        <div className="f-silder-title">
                                        {item.feedback}
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        ))}
              </Slider>
              
              <div style={{ textAlign: "right" }}>
          <button className="c-btn" onClick={this.previous}>
            <KeyboardBackspaceIcon/>
          </button>
          <button className="c-btn" onClick={this.next}>
            <ArrowRightAltIcon/>
          </button>
        </div>
        </div>
                </div>
                </div></AnimatedComponent>
            </section>

        );
    }
}

