import React from "react";
import img from '../../../assets/images/original/happy/c-1.jpeg';
import img1 from '../../../assets/images/original/happy/c-2.jpeg';
import img2 from '../../../assets/images/original/happy/c-3.jpeg';
import img3 from '../../../assets/images/original/happy/c-4.jpeg';
// import img4 from '../../../assets/images/original/happy/c-6.jpg';
import img5 from '../../../assets/images/original/happy/c-5.jpg';
import img6 from '../../../assets/images/original/happy/c-7.jpeg';
import img7 from '../../../assets/images/original/happy/c-8.jpg';
import img8 from '../../../assets/images/original/happy/c-13.jpg';
import img9 from '../../../assets/images/original/happy/c-10.jpeg';
import img10 from '../../../assets/images/original/happy/c-18.jpg';
import img11 from '../../../assets/images/original/happy/c-12.jpg';
import img12 from '../../../assets/images/original/happy/c-14.jpg';
import img13 from '../../../assets/images/original/happy/c-15.jpg';
import img14 from '../../../assets/images/original/happy/c-16.jpg';
import img15 from '../../../assets/images/original/happy/c-17.jpg';
import img16 from '../../../assets/images/original/happy/c-19.jpg';

import im1 from '../../../assets/images/original/happy/m1.jpeg';
import im2 from '../../../assets/images/original/happy/m2.jpeg';
import im4 from '../../../assets/images/original/happy/m4.jpg';
import im5 from '../../../assets/images/original/happy/m5.jpeg';
import im7 from '../../../assets/images/original/happy/m7.jpeg';
import im3 from '../../../assets/images/original/happy/m.jpeg';
import im8 from '../../../assets/images/original/happy/m-8.jpeg';
import im9 from '../../../assets/images/original/happy/m-16.jpg';
import im10 from '../../../assets/images/original/happy/m-10.jpg';
import im11 from '../../../assets/images/original/happy/m-11.jpg';
import im12 from '../../../assets/images/original/happy/m-12.jpg';
import im13 from '../../../assets/images/original/happy/m-13.jpg';
import im14 from '../../../assets/images/original/happy/m-14.jpg';
import im15 from '../../../assets/images/original/happy/m-15.jpg';
import im16 from '../../../assets/images/original/happy/m-17.jpg';

import im17 from '../../../assets/images/original/happy/m-18.jpg';

import i1 from '../../../assets/images/facility/fc6.jpeg';
import i2 from '../../../assets/images/facility/fc3.jpeg';
import i3 from '../../../assets/images/facility/fc9.jpeg';

import { useMediaQuery } from "react-responsive"; 
import border from '../../../assets/images/under/i3.png';

import bg from '../../../assets/images/original/about/4.jpg';
import { Fade } from "react-awesome-reveal";
import RoomCarosual from "../rooms/carosual";

export default function Customer() {

  const isDesktop = useMediaQuery({
    query: "(min-width: 992px)",
  });


  const customerDetail = [
    { img: im1, delay :800, direction: "down", right: 'Admin', overlay:'class-1' },
    { img: im2, delay :900, direction: "left",  right: 'Admin', overlay:'class-2' },
    { img: im4, delay :900, direction: "down",  right: 'Admin', overlay: 'class-3' },
    { img: im3, delay :800, direction: "down",  right: 'Admin', overlay: 'class-3' },
    { img: im5, delay :800, direction: "down",  right: 'Admin', overlay: 'class-3' },
    { img: im7, delay :800, direction: "down",  right: 'Admin', overlay: 'class-3' },
    { img: im8, delay :800, direction: "down",  right: 'Admin', overlay: 'class-3' },
    { img: im9, delay :800, direction: "down",  right: 'Admin', overlay: 'class-3' },
    { img: im10, delay :800, direction: "down",  right: 'Admin', overlay: 'class-3' },
    { img: im11, delay :800, direction: "down",  right: 'Admin', overlay: 'class-3' },
    { img: im12, delay :800, direction: "down",  right: 'Admin', overlay: 'class-3' },
    { img: im13, delay :800, direction: "down",  right: 'Admin', overlay: 'class-3' },
    { img: im14, delay :800, direction: "down",  right: 'Admin', overlay: 'class-3' },
    { img: im15, delay :800, direction: "down",  right: 'Admin', overlay: 'class-3' },
    { img: im16, delay :800, direction: "down",  right: 'Admin', overlay: 'class-3' },
    { img: im17, delay :800, direction: "down",  right: 'Admin', overlay: 'class-3' },
    
];

  return (
    <div className="">
       {/* ,filter: "blur(10px)" */}
        <div className="section happy-top bg-room-section" style={{ backgroundImage: "url(" + bg + ")", backgroundSize:"cover"}}>
        <div className="justify-content-center w-100 frent-content nearby">
                    <div className="header-text w-header">
                        <div>Happy Customers </div>
                    </div>
                    <div className="d-flex justify-content-center header-img"><img src={border} /></div>
        </div>  
      </div>
      <div className="p-3">
        <div className="happy-cutomer-card">

      {isDesktop?<div className="row">
            
              <div className="col-sm-7 col-md-7 col-lg-7 px-3 pb-5 h-100">
                <Fade delay={100} direction={"left"}>
                {/* <div className="c-all"> */}
                {/* <div className="h-image-card">
                  <div className="content-box"> */}
                  <div className="customer-image-card">
                  <img src={img} alt="Image not found" />
                  </div>
                  {/* <div className="customer-content">
                    <div className="h-text">{ item.title }</div>
                  </div> */}
                  {/* </div>
                  
                </div> */}
                    {/* <div className={`overlay-box ${item.overlay}`}>
                    <div className="icons-over">
                      <div className="over-icon"><EmojiEmotionsOutlinedIcon className="icons-c" /></div>
                    </div>
                    <div className="justify-content-center">
                      <div className="over-content">
                        <p>"Rooms  and amenities is excellent. Food was not ready on time .
                        Otherwise I recommend people for the stay."</p>
                        </div>
                    </div>
                  </div> */}
                  {/* </div> */}
                  </Fade>
            </div>
            <div className="col-sm-5 col-md-5 col-lg-5 h-100">
              <div className="row h-100">
                <div className="col-12">
                <Fade delay={100} direction={"right"}>
                  <div className="customer-image-card">
                  <img src={img2} alt="Image not found" /></div>
                  </Fade></div>
                  <div className="col-12">
                <Fade delay={100} direction={"right"}>
                  <div className="customer-image-card">
                  <img src={img3} alt="Image not found" /></div>
                  </Fade></div>
                  </div>
                  
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6 h-100">
              <div className="row h-100">
                <div className="col-12">
                <Fade delay={100} direction={"right"}>
                  <div className="customer-image-card">
                  <img src={img1} alt="Image not found" /></div>
                  </Fade></div>
                  
                  </div>
                  
            </div>

            <div className="col-sm-6 col-md-6 col-lg-6 h-100">
              <div className="row h-100">
              <div className="col-12">
                <Fade delay={100} direction={"right"}>
                  <div className="customer-image-card">
                  <img src={img5} alt="Image not found" /></div>
                  </Fade></div>
                  
                  </div>
                  
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6 h-100">
              <div className="row h-100">
              <div className="col-12">
                <Fade delay={100} direction={"right"}>
                  <div className="customer-image-card">
                  <img src={img6} alt="Image not found" /></div>
                  </Fade></div>
                  
                  </div>
                  
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6 h-100">
              <div className="row h-100">
              <div className="col-12">
                <Fade delay={100} direction={"right"}>
                  <div className="customer-image-card">
                  <img src={img8} alt="Image not found" /></div>
                  </Fade></div>
                  
                  </div>
                  
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6 h-100">
              <div className="row h-100">
              <div className="col-12">
                <Fade delay={100} direction={"right"}>
                  <div className="customer-image-card">
                  <img src={img7} alt="Image not found" /></div>
                  </Fade></div>
                  
                  </div>
                  
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6 h-100">
              <div className="row h-100">
              <div className="col-12">
                <Fade delay={100} direction={"right"}>
                  <div className="customer-image-card">
                  <img src={img10} alt="Image not found" /></div>
                  </Fade></div>
                  
                  </div>
                  
            </div>
            
            <div className="col-sm-6 col-md-6 col-lg-6 h-100">
              <div className="row h-100">
              <div className="col-12">
                <Fade delay={100} direction={"right"}>
                  <div className="customer-image-card">
                  <img src={img11} alt="Image not found" /></div>
                  </Fade></div>
                  
                  </div>
                  
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6 h-100">
              <div className="row h-100">
              <div className="col-12">
                <Fade delay={100} direction={"right"}>
                  <div className="customer-image-card">
                  <img src={img12} alt="Image not found" /></div>
                  </Fade></div>
                  </div>
            </div>
            
            <div className="col-sm-6 col-md-6 col-lg-6 h-100">
              <div className="row h-100">
              <div className="col-12">
                <Fade delay={100} direction={"right"}>
                  <div className="customer-image-card">
                  <img src={img14} alt="Image not found" /></div>
                  </Fade></div>
                  
                  </div>
                  
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6 h-100">
              <div className="row h-100">
              <div className="col-12">
                <Fade delay={100} direction={"right"}>
                  <div className="customer-image-card">
                  <img src={img15} alt="Image not found" /></div>
                  </Fade></div>
                  
                  </div>
                  
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6 h-100">
              <div className="row h-100">
              <div className="col-12">
                <Fade delay={100} direction={"right"}>
                  <div className="customer-image-card">
                  <img src={img9} alt="Image not found" /></div>
                  </Fade></div>
                  
                  </div>
                  
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6 h-100">
              <div className="row h-100">
              <div className="col-12">
                <Fade delay={100} direction={"right"}>
                  <div className="customer-image-card">
                  <img src={img13} alt="Image not found" /></div>
                  </Fade></div>
                  
                  </div>
                  
                  <div className="row h-100">
              <div className="col-12">
                <Fade delay={100} direction={"right"}>
                  <div className="customer-image-card">
                  <img src={img16} alt="Image not found" /></div>
                  </Fade></div>
                  
                  </div>
                  
            </div>

        </div>:<>
        <RoomCarosual data={customerDetail} silde={1} name={'facility'} facility={'facility'}/></>}
      
      </div>
      <div className="justify-content-center w-100 pb-2 pt-5 frent-content nearby">
                    <div className="header-text w-header">
                        <div className="nearby-text">Nearby Natural Views</div>
                    </div>
                    <div className="d-flex justify-content-center header-img"><img src={border} /></div>
                </div> 
      <div className="row pt-5 pb-5">
        <div className="col-lg-4 col-sm-4 col-md-4">
          <div className="n-card">
            <img src={i1} alt="img not found"/>
          <div className="n-card-text">Eeratty Water Falls</div>
          </div>
        </div>
        <div className="col-lg-4 col-sm-4 col-md-4">
          <div className="n-card">
            <img src={i2} alt="img not found"/>
          <div className="n-card-text">Varattupallam Dam</div>
          </div>
        </div>
        <div className="col-lg-4 col-sm-4 col-md-4">
          <div className="n-card">
            <img src={i3} alt="img not found"/>
          <div className="n-card-text">Thamaraikulam Pond</div>
          </div>
        </div>
      </div>

    </div>
    </div>
  );
}
