import React from 'react';
import { Fade } from "react-awesome-reveal";
import { useEffect, useState } from "react";

import img from '../../../../assets/images/original/room/sroom/1.jpg';
import img1 from '../../../../assets/images/original/room/sroom/2.jpg';
import img2 from '../../../../assets/images/original/room/sroom/3.jpg';
import img3 from '../../../../assets/images/original/room/sroom/4.jpg';
import img4 from '../../../../assets/images/original/room/sroom/5.jpg';
import img5 from '../../../../assets/images/original/room/sroom/6.jpg';
import img6 from '../../../../assets/images/original/room/sroom/3.jpg';

import RoomCarosual from '../carosual';

const carosual_data = [
  { img: img, id: 1 },
  { img: img1, id: 2 },
  { img: img2, id: 3 },
  { img: img3, id: 4 },
  { img: img4, id: 5 },
  { img: img5, id: 6 },
  { img: img6, id: 7 },
];
export default function SuiteRoom() {
  const [fullImg, setFullImage] = useState(img);
  // useEffect(() => {
  //   setFullImage(localStorage.getItem("room-img"));
  // }, [localStorage.getItem("room-img")])

  useEffect(() => {
    document.body.scrollTop =0;
    document.documentElement.scrollTop = 0;
},[])

  return (
    <section className="room-main">
      

      <div className="row pb-3">
        <div className="col-lg-8 col-sm-8 col-md-8">
        <Fade delay={600} direction={"left"}>
          <div className="image-card">
            <img src={fullImg} alt="image not found" />
          </div>
          </Fade>
        </div>
        <div className="col-lg-4 col-sm-4 col-md-4">
        <Fade delay={600} direction={"right"}>
          <div className="content-card">
            <div className="top-content">
              <div className="c-in">Check in: 12:00 PM Noon</div>
              <div className="c-in">Check Out: 10:00 AM Next day Morning</div>
              {/* <div className="c-in">Limited Breakfast is complementary</div> */}
              {/* <div className="room-amount"> &#x20B9;3999/- + Taxes</div> */}
              <div className="c-tax">* For Extra Bed 699 /- ( + Taxes)</div>
              <div className="c-tax">* Swimming pool, Games are Freely accessible</div>
            </div>
            <div className="book-now">
              <button className="b-now">Book Now</button></div>
          </div>
          </Fade>
        </div>
      </div>
      <div className="join-membership">
      <div class="justify-content-start room-title">
        <div>Room Details:</div>
         <div class="bottom-border"></div></div>
         <div class="sub-text-room">
        Moonwalk Resorts is a very serene and peaceful environment inside a dense forest.
        It is the perfect escape for hill and nature lovers.
        Out of Rooms you can just enjoy Our Dense Forest Culture surrounded amidst of birds and
         smog surrounded with a pleasent ambience.</div>
         <div class="sub-text-room">
        The climate here will never vary in our heavenly land,It will always be a soothing stay here and 
        no matter wherever you go and whatever you feel.The presence of nature will always bring a sunshine 
        in your life and peace of mind is a rare gift,peaceful sleeps and peaceful spirits are all our 
        luxuries that few rebels can never afford but that you can feel in our heavenly land.
        </div>
        </div>
      <Fade delay={600} direction={"down"}>
      <RoomCarosual data={carosual_data} silde={3} name={''} facility={''} />
      </Fade>
      <div>
        <div className="room-title-card pt-3">Room Facitlity</div>
        <Fade delay={600} direction={"down"}>
        <div className="row">
          {/* <div className="col-sm-3">
            <div className="rom-card">
              <div className="rom-head py-4">Complmentary Breakfast</div>
              <div className="rom-sub">Yes</div>
            </div>
          </div> */}
          <div className="col-sm-3">
            <div className="rom-card">
              <div className="rom-head py-4">Accommodation</div>
              <div className="rom-sub">2 Adults</div>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="rom-card">
              <div className="rom-head py-4">Bed types</div>
              <div className="rom-sub">King Sized bed</div>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="rom-card">
              <div className="rom-head py-4">Bathroom</div>
              <div className="rom-sub2">Attached Bathroom</div>
            </div>
          </div>
        </div>
        </Fade>
      </div>
    </section>

  );
}

