import React from 'react';
import { Link } from 'react-router-dom';

import GroupAddIcon from '@mui/icons-material/GroupAdd';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import KingBedIcon from '@material-ui/icons/KingBed';

import './style.css';

import img from '../../../assets/images/original/room/sroom/r.jpg';
import img1 from '../../../assets/images/original/room/droom/r.jpg';
import img2 from '../../../assets/images/original/room/froom/r.jpg';

import bg from '../../../assets/images/original/main-img/4.png';
import border from '../../../assets/images/under/i3.png';
import RoomsDetail from '../rooms';

import { Fade } from "react-awesome-reveal";


const roomDetail = [
    { img: img1, id:1, icon:'', delay:400, room: 'Economy Deluxe Room', price: '2,999.00/', icon1: 'read', link:'deluxRoom' },
    { img: img2, id:2, icon:'', delay:800, room: 'Dormitory Friends Room', price: '999.00/ per head', icon1: 'read', link:'friendRoom'},    
    { img: img, id:3, icon:'', delay:100, room: 'Premium Suite Room', price: '3,999.00/', icon1: 'read', link:'suiteRoom'},
];

export default function Rooms() {

    const activeRoom=(room, icon)=>{
        localStorage.setItem('activeRoom',room);
        localStorage.setItem('readmore',icon);
      }
    
    return (
        <div>
            <div className="section bg-room-section pb-5" style={{ backgroundImage: "url(" + bg + ")",backgroundSize:"cover" }}>
            <div className="justify-content-center w-100 pb-2 pt-5 frent-content">
                    <div className="header-text w-header">
                        <div>Types of Rooms</div>
                    </div>
                    <div className="d-flex justify-content-center header-img"><img src={border} /></div>
                </div>    
            <div className="p-4 row justify-content-center">
             <div className="room-text pb-5 w-100">
                 <p>

                    {/* Do remember to check out the interactive floor,
                    which the restaurant boasts of the restaurant is located at lobby level. */}
                 </p>
             </div>
            <div className="row frent-content px-0">
               {roomDetail.map((item)=>( 
               
               <div className="col-sm-4 col-lg-4 col-md-6 pt-3 px-2"> <Fade delay={item.delay}><div className="card-hover">
                   <div className="room-card">
                       <img src={item.img} alt="image not found"/>
                   </div>
                   {/* <div className="over-room-card">
                       <div className="over-card">
                        <button className="over-btn">Book Now</button></div>
                   </div> */}
                   <div className="room-content">
                       <div className="row">
                       <div className="col-9">
                                   <div className="w-100 card-title-content">{item.room}</div></div>
                                   <div className="col-3 d-flex">
                                   <div className="d-flex w-100 justify-content-end align"><KingBedIcon/></div>
                            <div className="card-icon icon-section"><GroupAddIcon className="icons"/></div>
                       </div>
                       </div>
                       <div className="d-flex w-100 card-justify">
                            <div className="price-tag"><b>&#8377;</b> {item.price}</div>
                            <Link onClick={()=>activeRoom(item.link,item.icon1)} to="/rooms"><InfoOutlinedIcon className="icons"/>&nbsp;Know more<ChevronRightIcon className="icons arrow-icons" /></Link>
                       </div>
                       <div className="small--text">{item.id===2?"(min 8 members + Taxes)":"(+ Taxes)"}</div>
                   </div></div></Fade>
                </div>))}
            </div>
            </div>
        </div>
        </div>
    )
}