import React, { useEffect, useState } from "react";
import { Fade } from "react-awesome-reveal";
import Feed from "instagram-feed-embed";
import Loading from "../../layouts/Loading"

export default function Map() {
    const [hide,setHide]=useState(true);
   useEffect(() => {
    setTimeout(()=> {
        setHide(false);
       },3000)
  }, []);

    return (
        <div className="">
            <div className="container">
            <div className="section map-section">
                <div className="p-2">
                    <div className="">
                        <div className="row">
                        <div className="col-sm-6 py-3">
                                {/* <Fade delay={100} direction={'top'}>hu */}
                                {hide?<div>
                                    <Loading/>
                                </div>:''}
                                <div className={`feed-insta ${hide? "" : "open-feeds"}`}>
                                <Feed
                                    userName="moonwalkresorts"
                                    limit={12}
                                    width={350}
                                    maxContainerHeight={510}
                                />
                                </div>
                                {/* </Fade> */}
                            </div>
                            <div className="col-sm-6 p-5 pt-3">
                                <Fade delay={100} direction={'top'}>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1589.3751627120328!2d77.56140755791237!3d11.761641329158689!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba94a829e89badd%3A0x85e0e1af2ba47d78!2sMoonwalk%20Resort!5e1!3m2!1sen!2sin!4v1634833299841!5m2!1sen!2sin" width="100%" height="500" style={{border:0}} allowfullscreen="" loading="lazy"></iframe>
                                </Fade>
                            </div>
                        </div>
                    </div>
                </div></div></div>
        </div>
    );
}



